import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import MD5 from 'crypto-js/md5';
import Box from '@mui/material/Box';
import CandidateHistoryTimeline from './CandidateTimeline';
import CandidateSkillSpiderChart from './CandidateSkillSpiderChart'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Rating from '@mui/material/Rating';
import CandidateNewSkillRating from './CandidateNewSkillRating';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CandidateTimeline from './CandidateTimelineNew';
import CandidateOverallScore from './CandidateOverallScore';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CandidateSkillsRating from './CandidateSkillsRating';
import Skeleton from '@mui/material/Skeleton';
import CandidateSkillsandExpertize from './CandidateSkillsandExpertize';
import CandidateJupyterTestButton from './CandidateJupyterTestButton';

function CandidateDetails(isLoading) {


  const [imageLoaded, setImageLoaded] = useState({});
  const handleImageLoaded = (candidateId) => {
    setImageLoaded((prev) => ({ ...prev, [candidateId]: true }));
  };
    const gravatarUrl = (email) => {
        const hash = MD5(email.trim().toLowerCase()).toString();
        return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=200`; // s=40 sets the size of the avatar
      };


    let { candidateId } = useParams(); // This hooks extracts params from the URL

  const [candidateData, setCandidateData] = useState({});
  const resumeUrl = `${process.env.REACT_APP_API_URL}${candidateData.resume}`;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };


  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    debugger;
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(apiurl+`/api/candidatesprofile/${candidateId}` , {
            headers: {
              'Authorization': `Token ${localStorage.getItem('authKey')}`,
              'Content-Type': 'application/json'
            },
          });
        setCandidateData(response.data);
        setError('');
      } catch (error) {
        console.error("Failed to fetch candidate data:", error);
        setError('Failed to fetch data. Please check the console for more information.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [candidateId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>

<div className='row' style={{padding:'0px',margin:'0px'}}>  
<div className='container' style={{width:'80%',marginTop:'10px'}}>
<div className='col-sm-12 col-md-12 col-xlg-12' style={{marginBottom:'10px'}} >
<div className="card shadow p-3 bg-body-tertiary rounded">
<div className="card-body" style={{paddingTop:'0px'}}>


    <div className='row' style={{background:'#0b5ed7',color:'#fff',borderRadius:'20px',padding:'10px',marginBottom:'15px'}}>
    <div className='col-3' style={{textAlign: 'center'}}>
                  {!imageLoaded[candidateData.id] && (
                    <Skeleton sx={{ bgcolor: '#408eff' }} variant="circular" width={200} height={200} />
                  )}
                  <img 
                    src={gravatarUrl(candidateData.email)} 
                    style={{
                      borderRadius: '50%', 
                      border: '5px solid #ccc', 
                      display: imageLoaded[candidateData.id] ? 'inline' : 'none'
                    }}
                    onLoad={() => handleImageLoaded(candidateData.id)}
                  />
    <br/><span ><span style={{fontSize:'15px',paddingLeft:'10px',fontWeight:'normal',margin:'10px',border:'1px solid #fff'}} class="badge text-bg-primary">{candidateData.user_current_status}</span></span></div>
    <div className='col' style={{textAlign:'left',lineHeight:'30px'}}><h5 className="card-title" style={{paddingTop:'15px',paddingLeft:'10px',fontSize:'2em'}}>{capitalizeWords(candidateData.name)}</h5>
    <span style={{fontSize:'15px',paddingLeft:'10px'}}>Id: {candidateId} </span>
    <br/><span style={{fontSize:'15px',paddingLeft:'10px'}}><strong>Role: </strong>{candidateData.job_posting_title}</span>
    <br/><span style={{fontSize:'15px',paddingLeft:'10px'}}><strong>Email:</strong> {candidateData.email} </span>
    <br/><span style={{fontSize:'15px',paddingLeft:'10px'}}><strong>Experiance:</strong> {candidateData.total_years_exp} Years</span>
    <br/><span style={{fontSize:'15px',paddingLeft:'10px'}}><strong>Notice Period:</strong> {candidateData.notice_period_length} Days</span>
    <br/><span style={{fontSize:'15px',paddingLeft:'5px'}}><a style={{color:'#fff'}} target="_blank" href={resumeUrl}><AttachFileIcon/>Resume</a></span>
    </div>
    <div className='col' style={{paddingTop:'50px',textAlign:'center'}}><CandidateOverallScore candidateId={candidateId}/>
  
    </div>
  </div>
  <div className='row' style={{background:'#ffffff',padding:'10px',borderRadius:'20px',border:'1px solid #ccc'}}>
  
<div className='col-6' >

<CandidateSkillsandExpertize  candidateId={candidateId} />

</div>
<div className='col-6' style={{paddingTop:'30px'}}>

<CandidateSkillSpiderChart candidateId={candidateId} />

</div>

<div style={{height:'100%',width:'100%',padding:'20px',position:'relative'}}>
<h4>Practical Test Details</h4>
    <hr/>
    <CandidateJupyterTestButton candidateId={candidateId} />

  
  
  
  </div>


<div style={{height:'100%',width:'100%',padding:'20px',position:'relative'}}>
<h4>Recruitment History</h4>
    <hr/>
  <CandidateTimeline  candidateId={candidateId} />
  
  
  
  </div>
    </div>

  
  
</div>
</div>
</div>


  
</div> 
    
    </div> 

      
    </div>
  );
}

export default CandidateDetails;
