import React, { useEffect, useState } from 'react';
import './CandidateJobStatusCountsTable.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CandidateProfileListingDatagrid from './CandidateProfileListingDatagrid';

const userToken = localStorage.getItem('authKey');
const apiurl = process.env.REACT_APP_API_URL;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  maxHeight: '800px',
  marginTop: '10px'
};

const JobStatusCountsTable = () => {
  const [data, setData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedJobPostingTitle, setSelectedJobPostingTitle] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;
  const userType = localStorage.getItem('usertype'); // Fetch user type (admin or hrmanager)
  const userCategory = localStorage.getItem('usercategory'); // Fetch user category (admin or hrmanager)

  useEffect(() => {
    const fetchJobListingsStatusCounts = async () => {
        try {
            let endpoint = '/api/job-listings/status-counts/';
            if (userCategory === 'hrmanager') {
                endpoint = '/api/job-listings/status-counts-by-hr-manager';
            }

            const response = await fetch(apiurl + endpoint, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authKey')}`,
                    'Content-Type': 'application/json'
                },
            });

            const rawData = await response.json();
            setData(rawData);

            // Extract all unique statuses from the data
            const allStatuses = new Set();
            rawData.forEach(item => {
                Object.keys(item.status_counts).forEach(status => {
                    allStatuses.add(status);
                });
            });

            setStatuses([...allStatuses]);
        } catch (error) {
            console.error('Error fetching job listings status counts:', error);
        }
    };

    fetchJobListingsStatusCounts();
}, [apiurl, userCategory]);


  const handleOpen = (jobPostingTitle, status, type) => {
    setSelectedJobPostingTitle(jobPostingTitle); // Set the job posting title
    setSelectedStatus(status); // Set the status
    setSelectedType(type); // Set the type
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <table className='table table-striped' style={{fontSize:'13px'}}>
        <thead>
          <tr>
            <th>Roles</th>
            {statuses.map(status => (
              <th key={status}>{status}</th>
            ))}
          </tr>
        </thead>
        <tbody>
  {data.filter(item => Object.values(item.status_counts).some(count => count !== null && count !== undefined && count !== '')).map((item, index) => (
    <tr key={index}>
      <td>{item.title}</td>
      {statuses.map(status => (
        <td key={status} onClick={(e) => { e.stopPropagation(); handleOpen(item.title, status, 'history'); }}>
          {item.status_counts[status] || '-'}
        </td>
      ))}
    </tr>
  ))}
</tbody>

      </table>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{maxHeight: '800px', overflow: 'scroll'}}
      >
        <Box sx={style}>
          <Button onClick={handleClose} style={{marginTop: '20px', textAlign: 'right', float: 'right', textTransform: 'capitalize', color: 'black'}}>Close</Button>
          <div style={{clear: 'both'}}></div>
          
          <CandidateProfileListingDatagrid jobPostingTitle={selectedJobPostingTitle} status={selectedStatus} type={selectedType}/>
        </Box>
      </Modal>
    </>
  );
};

export default JobStatusCountsTable;