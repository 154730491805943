import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Autocomplete, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Modal, Box, Typography , Select,MenuItem} from '@mui/material';
import TestPopup from './CandiateTestSendPopUp';
import CandidateCompanySkillFilter from './CandidateCompanySkillFilter';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1000px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const userToken = localStorage.getItem('authKey');
const apiurl = process.env.REACT_APP_API_URL;
const statusOptions = ['Screened','Shortlisted','Internal Interview','Theoretical Round','Case Study Round','Managerial Round','Onboarding','Rejected','Expensive']; // Replace with your actual options















function CandidateFilter() 
{

  const [comment, setComment] = useState(''); // State for the comment text
  const [score, setScore] = useState(''); // State for the comment text
  const [skill, setSkill] = useState(''); // State for the comment text
  const [ext_comment, setext_comment] = useState(''); 
  const [ext_status, setext_status] = useState(''); 
  const [assessed_skills, setassessed_skills] = useState(''); 
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [originalCandidates, setOriginalCandidates] = useState([]); // State to hold the original list of candidates
  const [filterOption, setFilterOption] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [selectedChip, setSelectedChip] = useState(null); // State to keep track of the selected chip
  const [candidatesFetched, setCandidatesFetched] = useState(false); // Track if candidates are fetched

  const [currentRowData, setCurrentRowData] = useState(null); // State to store current row data
  const [jobPostingsFetched, setJobPostingsFetched] = useState(false);

  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  
    const [statusCounts, setStatusCounts] = useState({});
    const [jobPostings, setJobPostings] = useState([]);
    const [selectedJobTitle, setSelectedJobTitle] = useState('');
    const [candidates, setCandidates] = useState([]);
    const [ setSelectedJob] = useState('');
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState(rows);


    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});


    const initialColumns = [
      // Add your basic candidate info columns here

      { field: 'user_current_status', headerName: 'Status', width: 150 },    
      { field: 'comments', headerName: 'Comment', width: 150 },    
      { field: 'id', headerName: 'Id', width: 50 },
      { field: 'name', headerName: 'Name', width: 150 },
      { field: 'email', headerName: 'Email', width: 200 },
      { field: 'phone_number', headerName: 'Phone Number', width: 130 },
      { field: 'current_salary', headerName: 'CTC', width: 130,type:'number' },
      { field: 'expected_salary', headerName: 'Exp CTC', width: 130 ,type:'number'},
      { field: 'applied_on', headerName: 'Date', width: 120 ,type: 'date',
        valueGetter: (params) => {
          return params.value ? new Date(params.value) : null;
        },},
      { field: 'notice_period_length', headerName: 'Notice', width: 130 ,type:'number'},
      { field: 'total_years_exp', headerName: 'Total Exp', width: 130,type:'number' },
      { field: 'job_posting_title', headerName: 'Job Post', width: 130 },   
      { field: 'score', headerName: 'JD Match', width: 100, type: 'number' }, // Add the score column here

      {
        field: 'resume',
        headerName: 'Resume',
        width: 120,
        disableColumnMenu: true,
        renderCell: (params) => {
          // Check if resume URL exists and if port 8000 is not already in the URL
          if (params.value && !params.value.includes(':8000')) {
            // Modify the URL to include the port :8000
            const resumeUrl = params.value.replace(/\/\/(.*?)\//, '//$1:8000/');
            return (
              <Button 
                size="small"
                variant="contained" 
                color="primary" 
                onClick={() => window.open(resumeUrl, '_blank')}
                style={{ textTransform: 'capitalize', fontSize: '12px' }}
              >
                View
              </Button>
            );
          } else if (params.value) {
            // If the URL already contains port 8000, use it as is
            return (
              <Button 
                size="small"
                variant="contained" 
                color="primary" 
                onClick={() => window.open(params.value, '_blank')}
                style={{ textTransform: 'capitalize', fontSize: '12px' }}
              >
                View
              </Button>
            );
          } else {
            // Render placeholder if there's no resume URL
            return <span>No Resume</span>;
          }
        },
      },

    
    ];
    const [jobTitles, setJobTitles] = useState([]);
  
    const [columns, setColumns] = useState(initialColumns);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleOpen = (rowData) => {
     
      setCurrentRowData(rowData); // Set current row data
      setSelectedStatus(rowData.user_current_status); // Set selected status based on current row
      setComment(rowData.comments);
    };
    const handleRowClick = (params) => {
      setSelectedRow(params.row);
      setSelectedStatus(params.row.user_current_status); // Set the dropdown to the row's current status
      setComment(params.row.comments);

      setOpen(true);
    };
  
    const handleCommentChange = (event,values) => {
      setComment(event.target.value);
    };

    const handleClose = () => setOpen(false);
 
    const handleChange = (event,values) => {
      setSelectedStatus(values);
    };

    const handleScoreChange = (event) => {
      setScore(event.target.value); // Update to handle score changes
    };
  
    const handleSkillChange = (event) => {
      setSkill(event.target.value); // Update to handle score changes
    };
    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };
    const handleFilteredCandidates = (filteredCandidateIds) => {
      const filteredCandidates = originalCandidates.filter(candidate => filteredCandidateIds.includes(candidate.id))
        .map(candidate => {
          const { questions, ...rest } = candidate;
          const questionFields = questions.reduce((acc, question) => {
            acc[removeStopWords(question.question_text)] = question.answer_text;
            return acc;
          }, {});
          return {
            ...rest,
            ...questionFields
          };
        });
      setRows(filteredCandidates);
    };
  
    const [showPopup, setShowPopup] = useState(false); // State to control popup visibility

const handleTestSend = () => {
  setShowPopup(true); // Show the popup when the button is clicked
};

const closePopup = () => {
  setShowPopup(false); // Close the popup
};

    const stopWords = ['what', 'your', 'in', 'with', 'have', 'you', 'how', 'many', 'whats']; // Define more stop words as needed
  
    const removeStopWords = (text) => {
      return text.split(' ')
        .filter(word => !stopWords.includes(word.toLowerCase()))
        .join(' ');
    };


    const statusSubmit = async () => {
      const payload = {
        user_current_status: selectedStatus,
        comments: comment,
        score: score, // Include score in your payload if needed  
        assesed_skills:skill,  

      };
      
      const apiURL =apiurl+ `/api/initial_applications_status/${selectedRow.id}/`; // Adjust "YourAPIBaseURL" and ensure `selectedRow.id` is correct
  
      try {
        const response = await axios.patch(apiURL, payload, {
          headers: {
            Authorization: `Token ${localStorage.getItem('authKey')}`,
          },
        });
        // console.log(response.data); // Handle success response
        const updatedRows = rows.map((row) =>
        row.id === selectedRow.id ? { ...row, user_current_status: selectedStatus,comments: comment } : row
      );
      setRows(updatedRows); // Assuming you have a state called rows that holds your DataGrid data
  
        handleClose(); // Close the modal upon successful API call
          
      } catch (error) {
        console.error('Failed to update status:', error); // Handle error case
      }
    };


    const handleKeyDown = async (params, event) => {
        if (event.key === 'Enter') {
          const newValue = event.target.value;
      // console.log(newValue);
          const payload = { [params.field]: newValue };  // Update only the edited field
          const apiURL = apiurl + `/api/initial_applications/${params.id}/`;
          // console.log(payload);
          try {
            const response = await axios.patch(apiURL, payload, {
              headers: {
                Authorization: `Token ${localStorage.getItem('authKey')}`,
              },
            });
      
            
      
            // Update only the edited cell's value
            
            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event);
           // params.api.commitCellChange({ id: params.id, field: params.field });
          //  params.api.stopCellEditMode({ id: params.id, field: params.field });
         //   params.api.refreshRow(params.id);

          } catch (error) {
            console.error('Error updating:', error);
          }
        }
      };
      


      useEffect(() => {
        fetch(apiurl + '/api/jobpostings/')
          .then(response => response.json())
          .then(data => {
            setJobPostingsFetched(true);
            setJobPostings(data);
          })
          .catch(error => {
            console.error('Error fetching job postings:', error);
          });
      }, []);


      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(apiurl + '/api/initial_applications/', {
              headers: {
                Authorization: "Token " + userToken
              }
            });
            const statuses = response.data.map(app => app.user_current_status);
            const uniqueStatuses = [...new Set(statuses)].filter(status => status && status.trim() !== "");
            setUniqueStatuses(uniqueStatuses);
            
          // console.log(uniqueStatuses);
          } catch (error) {
            console.error('Error fetching data: ', error);
            // Handle error appropriately in your application
          }
        };
    
        fetchData();
      }, []); // Only re-run the effect if apiUrl or userToken changes


      const handleSelectionChange = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowsData = rows.filter((row) => selectedIDs.has(row.id));
        setSelectedRows(selectedRowsData);
    
        // Extract emails from the selected rows and update the state
        const emails = selectedRowsData.map(row => row.email); // Assuming 'email' is the field where emails are stored
        setSelectedEmails(emails);
    };
      


      const handleFetchCandidates = () => {
        if (selectedJobTitle) {
          setIsLoading(true); // Start loading
      
          axios.get(apiurl + '/candidate-applications/job-posting/' + selectedJobTitle, {
            headers: {
              Authorization: `Token ${localStorage.getItem('authKey')}`,
            }
          })
          .then(candidateResponse => {
            const candidates = candidateResponse.data;
      
            // Fetch scores for these candidates and job posting
            axios.get(apiurl + '/resume-processing-score/job-posting/' + selectedJobTitle, {
              headers: {
                Authorization: `Token ${localStorage.getItem('authKey')}`,
              }
            })
            .then(scoreResponse => {
              const scores = scoreResponse.data;
      
              // Map scores by candidate id for easy access
              const scoreMap = {};
              scores.forEach(score => {
                scoreMap[score.candidate_id] = score.score + '%'; // Append '%' symbol to score
              });
      
              // Merge scores into candidate data
              const candidatesWithScores = candidates.map(candidate => {
                const score = scoreMap[candidate.id];
                return { ...candidate, score: score || 'N/A' };
              });
      
              // Set state with merged data
              setOriginalCandidates(candidatesWithScores);
              setCandidatesFetched(true);
      
              // Proceed with processing the data as before
              const questionColumns = new Map();
              const statusCounts = {};
              const transformedData = candidatesWithScores.map(candidate => {
                const candidateData = { ...candidate };
                const status = candidate.user_current_status || 'Unknown';
                statusCounts[status] = (statusCounts[status] || 0) + 1;
                candidate.questions.forEach(question => {
                  const conciseText = removeStopWords(question.question_text);
                  let columnType = 'number';
                  if (typeof question.answer_text === 'number') { 
                    columnType = 'number';          
                  }
                  const answerText = question.answer_text !== null ? question.answer_text : ''; // Check for null value
                  candidateData[conciseText] = answerText;
                  if (!questionColumns.has(conciseText)) {
                    questionColumns.set(conciseText, {
                      field: conciseText,
                      headerName: conciseText,
                      width: 200,              
                      sortable: true,
                      filterable: true,
                      type: columnType,
                    });
                  }
                });
                
                return candidateData;
              });
      
              setStatusCounts(statusCounts);
              setColumns(initialColumns.concat(Array.from(questionColumns.values())));
              setRows(transformedData);
              setIsLoading(false); // Stop loading after data is fetched and processed
            })
            .catch(scoreError => {
              console.error("Error fetching scores:", scoreError);
              setIsLoading(false); // Stop loading on error
            });
          })
          .catch(candidateError => {
            console.error("Error fetching candidates:", candidateError);
            setIsLoading(false); // Stop loading on error
          });
        }
      };
                  
      
      const handleChipClick = (status) => {
        let filteredRows = [];
        let filterValue = ''; // Initialize filter value
        
        if (status === selectedChip) {
          // If the clicked chip is the same as the currently selected chip, display all candidates
          filteredRows = [...originalCandidates];
          setSelectedChip(null); // Reset the selected chip
        } else {
          if (status === 'All') {
            // If 'All' chip is clicked, display all candidates
            filteredRows = [...originalCandidates];
          } else if (status === 'Unknown') {
            // If 'Unknown' chip is clicked, display candidates where status is nothing
            filteredRows = originalCandidates.filter(row => !row.user_current_status);
            filterValue = ''; // Set filter value to empty string for unknown status
          } else {
            // Filter candidates based on the selected status from the original list
            filteredRows = originalCandidates.filter(row => row.user_current_status === status);
            filterValue = status; // Set filter value to selected status
          }
          setSelectedChip(status); // Update the selected chip
        }
      
        // Map over filteredRows to include the question-related fields
        filteredRows = filteredRows.map(candidate => {
          const { questions, ...rest } = candidate;
          const questionFields = questions.reduce((acc, question) => {
            acc[removeStopWords(question.question_text)] = question.answer_text;
            return acc;
          }, {});
          return {
            ...rest,
            ...questionFields
          };
        });
      
        // Update the rows to display the filtered rows
        setRows(filteredRows);
      
        // Update the filter option
        setFilterOption(filterValue);
      };
      
      
      
            
    

      return (
        <div style={{ height: 600, width: '100%' }}>
        <div className="card">
          <div className="card-header">
            Candidate Filter
          </div>
          <div className="card-body">
            <div className="d-flex flex-row mb-3">
              <div className="p-2">Choose Job Posting from the dropdown to get started</div>
              <div className="p-2">
                <select className='form-control' value={selectedJobTitle} onChange={(e) => setSelectedJobTitle(e.target.value)}>
                  <option value="">Choose Job Posting</option>
                  {jobPostings.map(job => (
                    <option key={job.id} value={job.title} title={job.description}>{job.title}</option>
                  ))}
                </select>
              </div>
              <div className="p-2">
                <button className='btn btn-primary' onClick={handleFetchCandidates}>Fetch Candidates</button>
              </div>
              {candidatesFetched && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#f0f0f0', color: '#555' }}
                    onClick={handleOpenModal}
                  >
                    More Filters
                  </Button>
                </>
              )}

        <div className="p-2"></div>
      </div>

      <div style={{ margin: '10px 0' }}>
      <div>
      
      <Modal open={openModal} onClose={handleCloseModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px' }}>
  <CandidateCompanySkillFilter onClose={handleCloseModal} onApplyFilter={handleFilteredCandidates} />
  
  </div>
</Modal>

    </div>
  <Chip
    key="All"
    label="All"
    clickable
    onClick={() => handleChipClick('All')}
    style={{ marginRight: '5px', marginBottom: '5px', cursor: 'pointer' }}
  />
  {Object.entries(statusCounts).map(([status, count]) => (
    <Chip
    key={status}
    label={`${status}: ${count}`}
    clickable
    onClick={() => handleChipClick(status)}
    style={{
      marginRight: '5px',
      marginBottom: '5px',
      cursor: 'pointer',
      backgroundColor: selectedChip === status ? '#1976d2' : '', // Apply blue color to selected chip
      color: selectedChip === status ? '#ffffff' : '' // Apply white color to font when chip is selected
    }}
  
  />
  
  ))}
</div>

        </div>
      </div>
      <Button
  variant="contained"
  color="secondary"
  size="small"
  style={{
    marginLeft: '1200px', // Adjusted for better responsiveness
    backgroundColor: '#1a75ff', // A more vibrant color
    color: '#FFF', // Ensured high contrast
    marginTop: '20px',
    marginRight: '20px', // Added some right margin for spacing on the right
    transition: 'background-color 0.3s, color 0.3s' // Smooth transition for hover effects
  }}
  onClick={handleTestSend}
  disabled={selectedEmails.length === 0}
  onMouseOver={({currentTarget}) => currentTarget.style.backgroundColor = '#48D1CC'} // Darker green on hover 
  onMouseOut={({currentTarget}) => currentTarget.style.backgroundColor = '#1a75ff'} // Revert on mouse out
>
  Send Test
</Button>



{/* Render the TestPopup component when showPopup is true */}
{showPopup && <TestPopup closePopup={closePopup} selectedEmails={selectedEmails} />}



      <DataGridPro
  rows={rows}
  columns={columns}
  pageSizeOptions={[100, 200, 300]}
  onRowClick={handleRowClick}
  selectionModel={selectedRows.map(row => row.id)}
  onRowSelectionModelChange={handleSelectionChange}
  checkboxSelection
  disableSelectionOnClick // Disables selection on row click
  slots={{ toolbar: GridToolbar }} // Enable toolbar for column visibility control
  filterMode="client" // Use 'client' if you want to filter on the client-side
  componentsProps={{
    toolbar: {
      // Add custom filter input based on selected chip value
      filterValue: selectedChip ? { user_current_status: selectedChip } : {},
      filterOperator: 'contains', // Set the filter operator as needed
    },
  }}
  pagination
  loading={isLoading}
  initialState={{
    sorting: {
      sortModel: [{ field: 'id', sort: 'desc' }],
    },
    pinnedColumns: { left: ['user_current_status'], right: ['comments', 'score'] }, // Pin the score column to the right
  }}
  sx={{
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#edeff6', // Example color - blue
      color: '#000000', // Adjust text color if needed
      // Add other styles as needed
    },
  }}
/>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
<div>

      

          <Typography id="modal-modal-title" variant="h6" component="strong">
           <p style={{padding:'20px'}}>  {selectedRow.name} <br/><span style={{fontSize:'12px'}}> Role  {selectedRow.job_posting_title}</span></p>
          </Typography>
     
          <div className="row">
        <div className="col-4" style={{paddingTop: '15px'}}>
        <Autocomplete
  value={selectedStatus}
  onChange={(event, newValue) => {
    if (typeof newValue === 'string') {
      setSelectedStatus(newValue);
    } else {
      setSelectedStatus(newValue ? newValue.toString() : '');
    }
  }}
  onInputChange={(event, newInputValue) => {
    setSelectedStatus(newInputValue);
  }}
  options={uniqueStatuses}
  freeSolo // Allows for input not specified in the options
  getOptionLabel={(option) => option ? option.toString() : ''}
  renderInput={(params) => <TextField {...params} label="User Current Status" />}
/>
        </div>
        <div className="col-4">
        <TextField
          label="Score (out of 10)" 
          fullWidth        
          value={score}
          onChange={handleScoreChange}         
          margin="normal"
        />
        </div>
        <div className="col-4">
        <TextField
          label="Skill Assessed" 
          fullWidth        
          value={skill}
          onChange={handleSkillChange}         
          margin="normal"
        />
        </div>
        <div className="col-12">
        <TextField
          label="Comments"
          multiline
          fullWidth
          rows={4}
          value={comment}
          onChange={handleCommentChange}         
          margin="normal"
        />
        </div>
      </div>
          

  
<button className='btn btn-primary' onClick={statusSubmit}>Save Status</button>
<button className='btn btn-default' onClick={handleClose}>Close</button>
</div>
        </Box>
      </Modal>
        </div>
      );

}

export default CandidateFilter