import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import timeline from 'highcharts/modules/timeline';


timeline(Highcharts);

const CandidateHistoryTimelineHighcharts = ({ candidateId }) => {
  const [options, setOptions] = useState({
  chart: {
    type: 'timeline',
    inverted: false,
  },
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  xAxis: {
    visible: false
  },
  yAxis: {
    visible: false,
    title: {
      text: ''
    },
    labels: {
      enabled: false // Disable the yAxis labels
    },
    type: 'category'
  },
  colors: ['#4185F3'],
  plotOptions: {
    timeline: {
      pointPlacement: 'on' // Adjust point placement to evenly distribute points
    }
  },
  series: [{
    dataLabels: {
      allowOverlap: false,
      format: '{point.label}'
    },
    marker: {
      symbol: 'square',
      lineWidth: 3 // Set the lineWidth to 0 to remove stroke from boxes
    },
    data: []
  }]
});

  const [overallRate, setOverallRate] = useState(0);
  const [overallScore, setOverallScore] = useState(0);
  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;
  const [isDataEmpty, setIsDataEmpty] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiurl}/api/candidate-history/${candidateId}`, {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        });
        if (response.data.length === 0) {
          setIsDataEmpty(true);
          return;
        } else {
          setIsDataEmpty(false);
        }
        // Aggregate scores for entries with the same user_current_status
        const aggregatedData = response.data.reduce((accumulator, entry) => {
          const key = entry.user_current_status;
          const score = parseFloat(entry.score || 0);
  
          if (key in accumulator) {
            accumulator[key].count++;
            accumulator[key].totalScore += score;
          } else {
            accumulator[key] = { count: 1, totalScore: score };
          }
  
          return accumulator;
        }, {});
  
        // Calculate average scores and create timelineData
        const timelineData = Object.keys(aggregatedData).map(key => {
          const averageScore = aggregatedData[key].totalScore / aggregatedData[key].count;
          const comment = response.data.find(entry => entry.user_current_status === key).comment || 'None';
          return {
            label: `<strong>${key}</strong><br>Score: ${averageScore.toFixed(1)}<br>Comment: ${comment}`,
            name: new Date(response.data.find(entry => entry.user_current_status === key).datetime).toDateString()
          };
        });


  
        setOptions(prevOptions => ({
          ...prevOptions,
          series: [{
            ...prevOptions.series[0],
            data: timelineData
          }]
        }));
  
      } catch (error) {
        console.error('Failed to fetch candidate history:', error);
      }
    };
  
    fetchData();
  }, [candidateId]);
  
  if (isDataEmpty) {
    return (
      <div style={{ paddingTop: '30px', textAlign: 'center' }}>
        No history data available for this candidate.
      </div>
    );
  }


  return (
    <>
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
      <div class="">
  <div class="p-2"> <HighchartsReact
        highcharts={Highcharts}
        options={options}
      /> 
          

      
       </div>
  
</div>
       
        
      </div>
     
    </>
  );
};

export default CandidateHistoryTimelineHighcharts;