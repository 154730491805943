// ThankYouPage.js
import React from 'react';


import './ThankYouPage.css'; // Import your custom CSS

const ThankYouPage = () => {
    return (
        <div>
      
        <div className="thank-you-page">
            
           <center> <h1>Thanks for submitting the details!</h1></center><br/>
           <center>  <p>We'll let you know via email for the next process.</p></center><br/>
            <hr/>
            <center> <p>Meanwhile you can follow our linkedin page for more Job Vaccancies</p></center><br/>
            <center><iframe style={{display:'block'}} src='https://hireflexglobal.com/linkedin/' scrolling="no"/></center>
            
        </div>
        
        
        </div>
    );
};

export default ThankYouPage;
