import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more'; // Required for the spider chart

HC_more(Highcharts); // Initialize the highcharts-more module

const userToken = localStorage.getItem('authKey');
const apiurl = process.env.REACT_APP_API_URL;

const SpiderChart = ({ candidateId }) => {
    const [hasData, setHasData] = useState(false); // State to track if there's data to display

  const [chartOptions, setChartOptions] = useState({
    chart: {
      polar: true      
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    pane: {
      size: '80%'
    },
    xAxis: {
      categories: [],
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '1rem',
            
       
        }
    }
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0
    },
    colors: [
      '#0000FF'
  ],
    
    series: [{
      name: 'Skills Score',
      data: [],
      pointPlacement: 'on'
    }]
  });

  useEffect(() => {
    const fetchSkills = async () => {
      const response = await fetch(apiurl+`/api/candidate-skills/${candidateId}/`);
      const data = await response.json();
      if (data.error) {
        console.error(data.error);
        setHasData(false);
      } else {
        const filteredData = data.filter(item => item.score !== null && item.score !== "");
        if (filteredData.length > 0) {
          // Calculate average score for repeated assessed skills
          const skillMap = {};
          filteredData.forEach(item => {
            if (!skillMap[item.assesed_skills]) {
              skillMap[item.assesed_skills] = { scores: [], count: 0 };
            }
            skillMap[item.assesed_skills].scores.push(parseFloat(item.score));
            skillMap[item.assesed_skills].count++;
          });
  
          const categories = Object.keys(skillMap);
          const scores = categories.map(skill => {
            const avgScore = skillMap[skill].scores.reduce((acc, curr) => acc + curr, 0) / skillMap[skill].count;
            return parseFloat(avgScore.toFixed(1)); // Round to one decimal point
          });
  
          setChartOptions(prevOptions => ({
            ...prevOptions,
            xAxis: { ...prevOptions.xAxis, categories },
            series: [{ ...prevOptions.series[0], data: scores }]
          }));
  
          setHasData(true);
        } else {
          setHasData(false);
        }
      }
    };
  
    fetchSkills();
  }, [candidateId]);
  

  if (!hasData) return null; // Or return some placeholder content

  return (
    <>
    <h4 >Assesed Skills & Score</h4>
    <hr/>
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
    </>
  );
};

export default SpiderChart;
