import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Container, Typography } from '@mui/material';
import StaffDropoff from './StaffDropoff';

const GroupedBarChart = () => {
  const { days, updateduser_ids } = useParams();
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log('URL Parameters:', { days, updateduser_ids }); // Log the URL parameters

    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiurl}/api/candidates_by_status?days=${days}&updateduser_ids=${updateduser_ids}`, {});
        console.log('API Response:', response.data);
        const transformedData = transformData(response.data);
        console.log('Transformed Data:', transformedData);
        setData(transformedData.data);
        setKeys(transformedData.keys);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [days, updateduser_ids, apiurl]);

  const transformData = (apiData) => {
    const allDates = [];
    const keys = new Set();

    // Collect all dates and status keys
    for (const user in apiData) {
      for (const date in apiData[user]) {
        if (!allDates.includes(date)) allDates.push(date);
        for (const status in apiData[user][date]) {
          keys.add(status);  // Collect only status as keys
        }
      }
    }

    // Initialize data structure
    const transformed = allDates.map(date => {
      const entry = { date };
      keys.forEach(key => {
        entry[key] = 0;
      });
      return entry;
    });

    // Populate data
    for (const user in apiData) {
      for (const date in apiData[user]) {
        const entry = transformed.find(item => item.date === date);
        for (const status in apiData[user][date]) {
          entry[status] += apiData[user][date][status];  // Aggregate counts for the same status
        }
      }
    }

    return { data: transformed, keys: Array.from(keys) };
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Recruiter Dashboard
      </Typography>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          {keys.map((key) => (
            <Bar key={key} dataKey={key} fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`} />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <StaffDropoff updatedUserId={updateduser_ids} />
    </Container>
  );
};

export default GroupedBarChart;
