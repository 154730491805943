import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const userToken = localStorage.getItem('authKey');
const apiurl = process.env.REACT_APP_API_URL;


const CandidatesCountByDayChart = () => {
  const [chartData, setChartData] = useState({
    categories: [],
    series: [{ name: 'Candidates Count', data: [] }],
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(apiurl+'/api/candidates/count-by-day/');
      const data = await response.json();
      const categories = data.map(item => item.day);
      const seriesData = data.map(item => item.count);
      setChartData({
        categories,
        series: [{ name: 'Candidates Count', data: seriesData }],
      });
    };

    fetchData();
  }, []);

  const chartOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: '',
    },
    credits: {
        enabled: false
   },
    xAxis: {
      categories: chartData.categories,
      title: {
        text: 'Day',
      },
    },
    yAxis: {
      title: {
        text: 'Count',
      },
    },
    series: chartData.series,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default CandidatesCountByDayChart;
