import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import Rating from '@mui/material/Rating';




const CandidateHistoryTimelineHighcharts = ({ candidateId }) => {
  const [options, setOptions] = useState({
    chart: {
      type: 'timeline',
      inverted: true,
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      title: {
        text: ''
      },
      type: 'category'
    },
    series: [{
      dataLabels: {
        allowOverlap: false,
        format: '{point.label}'
      },
      marker: {
        symbol: 'circle'
      },
      data: []
    }]
  });
  const [overallRate, setOverallRate] = useState(0);
  const [overallScore, setOverallScore] = useState(0);
  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiurl}/api/candidate-history/${candidateId}`, {
          headers: {
            Authorization: `Token ${userToken}`,
          },
        });
        const timelineData = response.data.map(entry => ({
          x: new Date(entry.datetime).getTime(),
          label: `<strong>${entry.user_current_status}</strong><br> ${entry.comment || 'None'}<br>Score: ${entry.score || 'N/A'}`,
          name: new Date(entry.datetime).toDateString()
        }));

        let totalScore = 0;
        let scoresCounted = 0;
        response.data.forEach(entry => {
          if (entry.score) {
            totalScore += parseFloat(entry.score);
            scoresCounted++;
          }
        });
        const averageScore = scoresCounted > 0 ? totalScore / scoresCounted : 0;
        const avgRate = (averageScore/10)*5;
        setOverallScore(averageScore);   
        setOverallRate(avgRate);
        console.log(avgRate);

        setOptions(prevOptions => ({
          ...prevOptions,
          series: [{
            ...prevOptions.series[0],
            data: timelineData
          }]
        }));
      } catch (error) {
        console.error('Failed to fetch candidate history:', error);
      }
    };

    fetchData();
  }, [candidateId]);

  return (
    <>
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
      <div class="d-flex flex-row mb-3">
  <div class="p-2 shadow-sm p-3 mb-5 bg-body-tertiary rounded" style={{border:'1px solid #ccc'}}>Overall Score<br/> <span style={{fontWeight:'bold',fontSize:'30px'}}>{overallScore.toFixed(2)}</span><br/>
  <Rating
          name="read-only"
          value={overallRate}
          precision={0.5}
          readOnly
        /> 
  </div>
  <div class="p-2"> <HighchartsReact
        highcharts={Highcharts}
        options={options}
      /> 
          

      
       </div>
  
</div>
       
        
      </div>
     
    </>
  );
};

export default CandidateHistoryTimelineHighcharts;
