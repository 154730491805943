import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Typography, Paper, CircularProgress } from '@mui/material';

const AddHRManager = () => {
    const [managerName, setManagerName] = useState('');
    const [email, setEmail] = useState('');
    const [department, setDepartment] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [vacancy, setVacancy] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [titleError, setTitleError] = useState('');

	const apiurl = process.env.REACT_APP_API_URL;


    useEffect(() => {
        setError('');
        setTitleError('');

        // Fetch HR manager details based on email
        const fetchHRManagerByEmail = async () => {
            if (email) {
                try {
                    const response = await axios.get(apiurl+`/api/hrmanagers/?email=${email}`);
                    const existingManager = response.data.find(manager => manager.email === email);
                    if (existingManager) {
                        // If HR manager with the email exists, set the manager name and department
                        setManagerName(existingManager.admin_name);
                        setDepartment(existingManager.department);
                    } else {
                        // Reset manager name and department if no matching HR manager found
                        setManagerName('');
                        setDepartment('');
                    }
                } catch (error) {
                    console.error('Error fetching HR manager by email:', error);
                }
            }
        };

        fetchHRManagerByEmail();
    }, [email]);

    const handleCheckExistingTitle = async () => {
        if (!title) return;
        try {
            const existingHRManager = await axios.get(apiurl+`/api/check-existing-hr-manager/${title}/`);
            if (existingHRManager.data.exists) {
                setTitleError('Job Title already exists');
            } else {
                setTitleError('');
            }
        } catch (error) {
            console.error('Error checking existing title:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!description || !managerName || !email || !department || !title || !vacancy) {
            setError('Please fill in all fields.');
            return;
        }
        if (titleError) {
            alert('An error occurred!');
            return;
        }

        // Add new HR manager
        setLoading(true);
        try {
            await axios.post(apiurl+'/api/hrmanagers/', {
                admin_name: managerName,
                email: email,
                department: department,
                title: title,
                description: description,
                vacancy: parseInt(vacancy)
            });
            setLoading(false);
            alert('Job Title added successfully!');
            setManagerName('');
            setEmail('');
            setDepartment('');
            setTitle('');
            setDescription('');
            setVacancy('');
        } catch (error) {
            setLoading(false);
            console.error('Error adding Job Title:', error);
            setError('An error occurred while adding Job Title.');
        }
    };


    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h4" gutterBottom>Add Job Titles</Typography>
            <Paper style={{ padding: 20 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label="Manager Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label="Manager Name"
                                value={managerName}
                                onChange={(e) => setManagerName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label="Department"
                                value={department}
                                onChange={(e) => setDepartment(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label="Job Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={handleCheckExistingTitle}
                                error={!!titleError}
                                helperText={titleError}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label="No of Vacancy"
                                type="number"
                                value={vacancy}
                                onChange={(e) => setVacancy(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Job Description"
                                multiline
                                rows={4}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Add Job Title'}
                            </Button>
                        </Grid>
                    </Grid>
                    {error && <Typography variant="body2" color="error">{error}</Typography>}
                </form>
            </Paper>
        </div>
    );
};

export default AddHRManager;
