import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiurl = process.env.REACT_APP_API_URL;

const LoginPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const sessionToken = sessionStorage.getItem('sessionToken');
    const sessionUser = sessionStorage.getItem('sessionUser');
    const sessionUserCategory = sessionStorage.getItem('sessionUserCategory');

    if (sessionToken && sessionUser === 'admin') {
      navigate('/adminhr');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiurl+'/dj-rest-auth/login/', {
        email: email,
        password: password,
      });
      const { key, hr_manager_id } = response.data; // Assuming HR manager ID is included in the login response
      sessionStorage.setItem('sessionToken', key);
      sessionStorage.setItem('sessionUser', 'admin');
      localStorage.setItem('authKey', key);
      localStorage.setItem('auth', true);
      localStorage.setItem('usertype', "admin");
      localStorage.setItem('usercategory', "hrmanager");
      localStorage.setItem('hrManagerId', hr_manager_id); // Store HR manager ID
      navigate('/admin/dash/candidatedashboard');
    } catch (err) {
      setError(err.response ? err.response.data.non_field_errors || err.response.data : 'An error occurred. Please try again.');
    }
  };
  

  return (
    <div className="container mt-5" style={{ width: '400px' }}>
      <div className="card">
        <div className="card-header">
          Login
        </div>
        <div className="card-body">
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
        {error && (
          <div className="alert alert-danger" role="alert" style={{ margin: '10px' }}>
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;