import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import MD5 from 'crypto-js/md5';
import CandidateProfileListingDatagrid from './CandidateProfileListingDatagrid';

const HoldCandidatesCount = () => {
  const [candidates, setCandidates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const apiurl = process.env.REACT_APP_API_URL;
  const userType = localStorage.getItem('usertype'); // Fetch user type (admin or hrmanager)
  const userCategory = localStorage.getItem('usercategory'); // Fetch user category (admin or hrmanager)

  useEffect(() => {
    const fetchHoldCandidates = async () => {
      try {
        let endpoint = '/api/candidates/hold/';
        if (userCategory === 'hrmanager') {
          endpoint = '/api/hold-candidates-by-hr-manager/';
        }
        const response = await fetch(apiurl + endpoint, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authKey')}`,
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        setCandidates(data); // Update state with candidate data
      } catch (error) {
        console.error('Failed to fetch hold candidates:', error);
      }
    };

    fetchHoldCandidates();
  }, [apiurl, userCategory]);

  // Generate Gravatar URL
  const gravatarUrl = (email) => {
    const hash = MD5(email.trim().toLowerCase()).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=40`; // s=40 sets the size of the avatar
  };

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',    
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    maxHeight: '90%',
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>{candidates.length}</h2>
      <AvatarGroup  max={3} style={{ justifyContent: 'center' }} onClick={() => setOpenModal(true)}>
      {Array.isArray(candidates) && candidates.map((candidate, index) => (
          <Tooltip key={index} title={capitalizeWords(candidate.name)} placement="top">
            <Avatar             
              key={index} 
              alt={candidate.name} 
              src={gravatarUrl(candidate.email)}             
            />
          </Tooltip>
        ))}
      </AvatarGroup>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='row'>
            <CandidateProfileListingDatagrid  jobPostingTitle='' status="hold"/>
          </div> 
        </Box>
      </Modal>
    </div>   
  );
};

export default HoldCandidatesCount;