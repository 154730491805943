import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import './TestSendPopup.css';

const apiurl = process.env.REACT_APP_API_URL;

const TestPopup = ({ closePopup, selectedEmails }) => {
    const [filter, setFilter] = useState('');
    const [subject, setSubject] = useState('Online Test');
    const [message, setMessage] = useState("We're excited to see what you bring to the table. Click the button below to start your test, and let's make this a great experience! 😊");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isSending, setIsSending] = useState(false); // New state for sending status
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        axios.get(`${apiurl}/testsendpopup/`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    useEffect(() => {
        const filtered = filter ? data.filter(item =>
            item.name.toLowerCase().includes(filter.toLowerCase()) ||
            item.description.toLowerCase().includes(filter.toLowerCase())
        ) : [];
        setFilteredData(filtered);
    }, [filter, data]);

    const handleCheckboxChange = (item) => {
        const newSelectedItems = selectedItems.includes(item)
            ? selectedItems.filter(selected => selected !== item)
            : [...selectedItems, item];
        setSelectedItems(newSelectedItems);
    };

    const isSelected = (item) => selectedItems.includes(item);

    const handleCompose = () => {
        setIsSending(true); // Update the sending state to true
        const payload = {
            subject: subject,
            message: message,
            emails: selectedEmails,
            selectedItems: selectedItems.map(item => item.name)
        };

        axios.post(`${apiurl}/send-message/`, payload)
            .then(response => {
                setAlert(<Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            Email Sent Successfully!
                         </Alert>);
                setTimeout(() => {
                    closePopup(); // Close the popup after showing success alert
                    setIsSending(false); // Reset the sending state after the operation
                }, 2000); // Close the popup after 2 seconds
            })
            .catch(error => {
                setAlert(<Alert severity="error">Failed to send the email.</Alert>);
                setIsSending(false); // Reset the sending state in case of an error
            });
    };

    return (
        <div className="popup-container">
    <div className="popup">
        <button onClick={closePopup} className="close-popup">X</button>
        <h2>Select Test</h2>
        
        {/* Filter input */}
        <label htmlFor="filter"></label>
        <input
            type="text"
            id="filter"
            placeholder="Filter by test name or description..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="filter-input"
        />
        
        {/* Subject input */}
        <label htmlFor="subject">Subject</label>
        <input
            type="text"
            id="subject"
            placeholder="Enter the subject for your mail..."
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="filter-input"
        />
        
        {/* Message input */}
        <label htmlFor="message">Mail content</label>
        <textarea
            id="message"
            placeholder="type your email message content..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="message-input"
            rows="4"
        />

        {/* Results table */}
        <div className="results">
            <table>
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Time (in min)</th>
                        <th>Number of Questions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map(item => (
                            <tr key={item.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={isSelected(item)}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                </td>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td>{item.time_limit}</td>
                                <td>{item.question_count}</td>
                            </tr>
                        ))
                    ) : filter ? <p>No data found</p> : null}
                </tbody>
            </table>
        </div>
        
        {/* Alert */}
        {alert}
        
        {/* Buttons */}
        <div className="buttons-container">
            <button 
                onClick={handleCompose} 
                className="compose-button"
                disabled={isSending || selectedItems.length === 0 || message.trim() === ''}
            >
                {isSending ? 'Sending...' : 'Send'}
            </button>
        </div>
    </div>
</div>

    );
};

export default TestPopup;
