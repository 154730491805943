import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import MD5 from 'crypto-js/md5';
import Box from '@mui/material/Box';
import CandidateHistoryTimeline from './CandidateTimeline';
import CandidateSkillSpiderChart from './CandidateSkillSpiderChart'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Rating from '@mui/material/Rating';
import CandidateOverallScore from './CandidateOverallScore';



const OnboardCandidatesCount = ({ jobPostingTitle, status }) => {

 
  const [selectedCandidate, setSelectedCandidate] = useState({ id: null, name: "",job:"",notes:"" });

  const [selectedCandidateId, setSelectedCandidateId] = useState(null);

  const [candidates, setCandidates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchOnboardCandidates = async () => {
      try {
        // Construct the URL using the props
        const url = `${apiurl}/api/candidates/getbystatusandrole?job_posting_title=${jobPostingTitle}&status=${status}`;
        const response = await fetch(url, {
          headers: {
            'Authorization': `Token ${userToken}`,
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        setCandidates(data); // Update state with candidate data
      } catch (error) {
        console.error('Failed to fetch onboard candidates:', error);
      }
    };
  

  

    


  }, [jobPostingTitle, status, apiurl]); // Add props to dependency array
  

  // Generate Gravatar URL
  const gravatarUrl = (email) => {
    const hash = MD5(email.trim().toLowerCase()).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=200`; // s=40 sets the size of the avatar
  };

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',    
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    maxHeight: '90%',
  };

  return (
    <div className='row' style={{padding:'2px'}}>  
 {candidates.map((candidate, index) => (
<div className='col-sm-6 col-md-4 col-xlg-3' style={{marginBottom:'10px'}} >
<div className="card shadow p-3 bg-body-tertiary rounded">
<div className="card-body">
    <div className='row'>
    <div className='col' style={{maxWidth:'60px'}}><img src={gravatarUrl(candidate.email)} style={{width:'60px',height:'60px',borderRadius:'50%'}}/></div>
    <div className='col'>
    
      <h5 className="card-title" style={{paddingTop:'5px',paddingLeft:'10px'}}>{capitalizeWords(candidate.name)}</h5>
    <span style={{fontSize:'13px',paddingLeft:'10px'}}>{candidate.job_posting_title}</span><br/>
    <span style={{fontSize:'13px',paddingLeft:'10px'}}>ID:- {candidate.id}</span>
    </div>
    <div className='col' style={{maxWidth:'150px',textAlign:'right'}}> <CandidateOverallScore candidateId={candidate.id}/>

    
    </div>
  </div>

  
  
</div>
</div>
</div>

  ))}

    
    </div> 
  );
};

export default OnboardCandidatesCount;
