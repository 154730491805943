import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CandidateTestList from './CandidateTestList';
const apiurl = process.env.REACT_APP_API_URL;

export default function TestsSubmitted() {
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function fetchCount() {
      try {
        const response = await fetch(apiurl + '/api/candidates-test/count/');
        const data = await response.json();
        setCount(data.total_candidates);
      } catch (error) {
        console.error('Error fetching count:', error);
      }
    }

    fetchCount();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
        <Title>Tests Submitted</Title>
        <Typography component="p" variant="h4">
          {count}
        </Typography>
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent>
          <CandidateTestList />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
