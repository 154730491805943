import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_funnel from 'highcharts/modules/funnel';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CandidateProfileListingDatagrid from './CandidateProfileListingDatagrid'; // Adjust the import path as necessary

// Initialize the funnel module
HC_funnel(Highcharts);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',    
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  maxHeight: '90%',
};

const CandidateFunnelChartWithData = () => {
  const [funnelData, setFunnelData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStage, setSelectedStage] = useState('');
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const apiurl = process.env.REACT_APP_API_URL;
      const userType = localStorage.getItem('usertype'); // Fetch user type (admin or hrmanager)
      const userCategory = localStorage.getItem('usercategory'); // Fetch user category (admin or hrmanager)
  
      let endpoint = '/api/candidate-history/';
      if (userCategory === 'hrmanager') {
        endpoint = '/api/candidate-history-by-hr-manager/';
      }
      const url = apiurl + endpoint;
  
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('authKey')}`,
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
  
        // Define statuses to be excluded
        const excludedStatuses = ["Rejected", "hold"];
  
        // Process and set the data for the funnel chart, excluding certain statuses
        const processedData = data.reduce((acc, item) => {
          if (!excludedStatuses.includes(item.user_current_status)) {
            const status = item.user_current_status;
            const count = (acc[status] || 0) + 1; // Increment the count for each status
            acc[status] = count;
          }
          return acc;
        }, {});
  
        // Convert the processed data into an array of [name, y] pairs for Highcharts
        const chartData = Object.entries(processedData).map(([name, y]) => [name, y]);
        chartData.sort((a, b) => b[1] - a[1]);
  
        setFunnelData(chartData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
  
    fetchData();
  }, []);

  // Prepare the chart options
  // Prepare the chart options
const options = {
  chart: {
    type: 'funnel',
    events: {
      click: function(event) {
        if (event.point && event.point.name) { // Check if event.point and event.point.name exist
          const clickedStageName = event.point.name;
          setSelectedStage(clickedStageName);
          setSelectedType('history'); // Set the type to 'history' for demonstration
          setOpenModal(true);
        }
      }
    }
  },
  // Other chart options...


    title: {
      text: 'Recruitment Funnel'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b> ({point.y:,.0f})',
          softConnector: true
        },
        center: ['40%', '50%'],
        neckWidth: '30%',
        neckHeight: '25%',
        width: '80%',
        cursor: 'pointer', // Change cursor to pointer on hover over the series
        point: {
          events: {
            click: function(event) {
              if (event.point) { // Check if event.point exists
                const clickedStageName = event.point.name;
                setSelectedStage(clickedStageName);
                setSelectedType('history'); // Set the type to 'history' for demonstration
                setOpenModal(true);
              }
            }
          }
          
        }
      }
    },
    series: [{
      name: 'Candidates',
      data: funnelData
    }]
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='row'>
            <CandidateProfileListingDatagrid jobPostingTitle="" status={selectedStage} type='history' />
          </div> 
        </Box>
      </Modal>
    </div>
  );
};

export default CandidateFunnelChartWithData;