import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const MAX_LEVEL = 5; // The maximum skill level (e.g., 5 dots)

const SkillRating = ({ skill, level }) => {
  // Normalize the level between 0 and 1
  const normalizedLevel = Math.min(Math.max(level, 0), 1);
console.log("sk"+skill);
console.log("l"+level);
  // Calculate the number of filled dots based on the normalized level
  const filledDots = Math.round(normalizedLevel * MAX_LEVEL);

  // Function to capitalize only the first letter of a string
  const capitalizeFirstLetter = (str) => {
    if (!str) return ''; // Return an empty string if str is null or undefined
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  

  // Create an array of dots based on the maximum level, where some are filled based on the level prop
  const dots = Array.from({ length: MAX_LEVEL }, (_, index) => (
    <Box
      key={index}
      sx={{
        width: 12, // Dot width
        height: 12, // Dot height
        borderRadius: '50%',
        backgroundColor: index < filledDots ? 'blue' : 'lightgrey', // Filled or unfilled color
        margin: 0.5,
      }}
    />
  ));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginY: 1 }}>
      <Typography sx={{ width: '150px'}}>{capitalizeFirstLetter(skill)}</Typography>
    
      <Box sx={{ display: 'flex' }}>{dots}</Box>
    </Box>
  );
};

export default SkillRating;