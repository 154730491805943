import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Autocomplete, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Modal, Box, Typography , Select,MenuItem} from '@mui/material';
import FunnelChart from './CandidateFunnelChart';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import timeline from 'highcharts/modules/timeline';
import annotations from 'highcharts/modules/annotations';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


timeline(Highcharts);
annotations(Highcharts);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const userToken = localStorage.getItem('authKey');
const apiurl = process.env.REACT_APP_API_URL;
const statusOptions = ['Screened','Shortlisted','Internal Interview','Theoretical Round','Case Study Round','Managerial Round','Onboarding','Rejected','Expensive']; // Replace with your actual options
const keywords = ["Screened", "Shortlisted", "Interview", "Case Study", "Technical", "Managerial", "Onboard"];















function CandidateFilter() 
{

    const [buttonLabel, setButtonLabel] = useState('Fetch Data');
    const [isDisabled, setIsDisabled] = useState(false);

    const [showAll, setShowAll] = useState(false);
    const [chartData, setChartData] = useState([]);

  const [comment, setComment] = useState(''); // State for the comment text
  const [score, setScore] = useState(''); // State for the comment text

  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [currentRowData, setCurrentRowData] = useState(null); // State to store current row data

  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  
    const [statusCounts, setStatusCounts] = useState({});
    const [jobPostings, setJobPostings] = useState([]);
    const [selectedJobTitle, setSelectedJobTitle] = useState('');
    const [candidates, setCandidates] = useState([]);
    const [ setSelectedJob] = useState('');
    const [rows, setRows] = useState([]);


    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});


    const initialColumns = [
      // Add your basic candidate info columns here

      { field: 'user_current_status', headerName: 'Status', width: 150 },    
      { field: 'comments', headerName: 'Comment', width: 150 },    
      { field: 'id', headerName: 'Id', width: 50 },
      { field: 'name', headerName: 'Name', width: 150 },
      { field: 'email', headerName: 'Email', width: 200 },
      { field: 'phone_number', headerName: 'Phone Number', width: 130 },
      { field: 'current_salary', headerName: 'CTC', width: 130,type:'number' },
      { field: 'expected_salary', headerName: 'Exp CTC', width: 130 ,type:'number'},
      { field: 'applied_on', headerName: 'Date', width: 120 ,type: 'date',
        valueGetter: (params) => {
          return params.value ? new Date(params.value) : null;
        },},
      { field: 'notice_period_length', headerName: 'Notice', width: 130 ,type:'number'},
      { field: 'total_years_exp', headerName: 'Total Exp', width: 130,type:'number' },
      { field: 'job_posting_title', headerName: 'Job Post', width: 130 },   
      {
        field: 'resume',
        headerName: 'Resume',
        width: 120,
        renderCell: (params) => {
          // Check if resume URL exists and if port 8000 is not already in the URL
          if (params.value && !params.value.includes(':8000')) {
            // Modify the URL to include the port :8000
            const resumeUrl = params.value.replace(/\/\/(.*?)\//, '//$1:8000/');
            return (
              <Button 
                size="small"
                variant="contained" 
                color="primary" 
                onClick={() => window.open(resumeUrl, '_blank')}
                style={{ textTransform: 'capitalize', fontSize: '12px' }}
              >
                View
              </Button>
            );
          } else if (params.value) {
            // If the URL already contains port 8000, use it as is
            return (
              <Button 
                size="small"
                variant="contained" 
                color="primary" 
                onClick={() => window.open(params.value, '_blank')}
                style={{ textTransform: 'capitalize', fontSize: '12px' }}
              >
                View
              </Button>
            );
          } else {
            // Render placeholder if there's no resume URL
            return <span>No Resume</span>;
          }
        },
      },
      
      
      
    
    ];
    const [jobTitles, setJobTitles] = useState([]);
  
    const [columns, setColumns] = useState(initialColumns);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleOpen = (rowData) => {
     
      setCurrentRowData(rowData); // Set current row data
      setSelectedStatus(rowData.user_current_status); // Set selected status based on current row
      setComment(rowData.comments);
    };
    const handleRowClick = (params) => {
      setSelectedRow(params.row);
      setSelectedStatus(params.row.user_current_status); // Set the dropdown to the row's current status
      setComment(params.row.comments);

      setOpen(true);
    };
  


    const handleCommentChange = (event,values) => {
      setComment(event.target.value);
    };

    const handleClose = () => setOpen(false);
 
    const handleChange = (event,values) => {
      setSelectedStatus(values);
    };

    const handleScoreChange = (event) => {
      setScore(event.target.value); // Update to handle score changes
    };
  
    const stopWords = ['what', 'your', 'in', 'with', 'have', 'you', 'how', 'many', 'whats']; // Define more stop words as needed
  
    const removeStopWords = (text) => {
      return text.split(' ')
        .filter(word => !stopWords.includes(word.toLowerCase()))
        .join(' ');
    };











    


    const statusSubmit = async () => {
   
      const payload = {
        user_current_status: selectedStatus,
        comment: comment,
        score: score, // Include score in your payload if needed
      };
      
      const apiURL =apiurl+ `/api/initial_applications_status/${selectedRow.id}/`; // Adjust "YourAPIBaseURL" and ensure `selectedRow.id` is correct
  
      try {
        const response = await axios.patch(apiURL, payload, {
          headers: {
            Authorization: `Token ${localStorage.getItem('authKey')}`,
          },
        });
        
        const updatedRows = rows.map((row) =>
        row.id === selectedRow.id ? { ...row, user_current_status: selectedStatus,comments: comment } : row
      );
      setRows(updatedRows); // Assuming you have a state called rows that holds your DataGrid data
  
        handleClose(); // Close the modal upon successful API call
          
      } catch (error) {
        console.error('Failed to update status:', error); // Handle error case
      }
    };


    const handleKeyDown = async (params, event) => {
        if (event.key === 'Enter') {
          const newValue = event.target.value;
          const payload = { [params.field]: newValue };  // Update only the edited field
          const apiURL = apiurl + `/api/initial_applications/${params.id}/`;
          try {
            const response = await axios.patch(apiURL, payload, {
              headers: {
                Authorization: `Token ${localStorage.getItem('authKey')}`,
              },
            });
      
            
      
            // Update only the edited cell's value
            
            params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event);
           // params.api.commitCellChange({ id: params.id, field: params.field });
          //  params.api.stopCellEditMode({ id: params.id, field: params.field });
         //   params.api.refreshRow(params.id);

          } catch (error) {
            console.error('Error updating:', error);
          }
        }
      };
      


    useEffect(() => {
        fetch(apiurl+'/api/jobpostings/')
          .then(response => response.json())
          .then(data => {
            setJobPostings(data);
          })
          .catch(error => {
            console.error('Error fetching job postings:', error);
          });
      }, []);


      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(apiurl + '/api/initial_applications/', {
              headers: {
                Authorization: "Token " + userToken
              }
            });
            const statuses = response.data.map(app => app.user_current_status);
            const uniqueStatuses = [...new Set(statuses)].filter(status => status && status.trim() !== "");
            setUniqueStatuses(uniqueStatuses);
            
          } catch (error) {
            console.error('Error fetching data: ', error);
            // Handle error appropriately in your application
          }
        };
    
        fetchData();
      }, []); // Only re-run the effect if apiUrl or userToken changes




      const [funnelData, setFunnelData] = useState([]);

      useEffect(() => {
        const fetchData = async () => {
          const url = apiurl+'/api/candidate-history/';
          try {
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                Authorization: `Token ${localStorage.getItem('authKey')}`,              
              }
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
    
            // Define statuses to be excluded
            const excludedStatuses = ["Rejected", "hold"];
    
            // Process and set the data for the funnel chart, excluding certain statuses
            const processedData = data.reduce((acc, item) => {
              if (!excludedStatuses.includes(item.user_current_status)) {
                const status = item.user_current_status;
                const count = (acc[status] || 0) + 1; // Increment the count for each status
                acc[status] = count;
              }
              return acc;
            }, {});
    
            // Convert the processed data into an array of [name, y] pairs for Highcharts
            const chartData = Object.entries(processedData).map(([name, y]) => [name, y]);
            chartData.sort((a, b) => b[1] - a[1]);

            setFunnelData(chartData);
          } catch (error) {
            console.error("Failed to fetch data:", error);
          }
        };
    
        fetchData();
      }, []);


/** Candidate History Time Series**/


  
/** Candidate History Time Series**/

      const handleSelectionChange = (event) => {
        setSelectedJob(event.target.value);
      };


      const handleFetchCandidates = () => {
        if (selectedJobTitle) {

            setButtonLabel('Fetching...');
            setIsDisabled(true);
            setIsLoading(true); // Start loading
           
            axios.get(apiurl + '/candidate-applications/job-posting/'+selectedJobTitle, {
              headers: {
                Authorization: `Token ${localStorage.getItem('authKey')}`,
              }
            })
            .then(response => {
                setButtonLabel('Fetch Data');
                setIsDisabled(false);
              const questionColumns = new Map();
              const statusCounts = {}; // Object to keep track of status count              
              const transformedData = response.data.map(candidate => {
                const candidateData = { ...candidate };
                const status = candidate.user_current_status || 'Unknown'; // Handle null or undefined statuses
                statusCounts[status] = (statusCounts[status] || 0) + 1;
                candidate.questions.forEach(question => {
                  const conciseText = removeStopWords(question.question_text);
                  let columnType = 'number'; // Default to text
                 
                  if (typeof question.answer_text === 'number') { 
                      
                      
                    columnType = 'number'; // Set to number if the answer is numerical                
                  }
                
                 // { field: 'job_posting', headerName: 'Job Cat', width: 120, type: 'text', },
                  if (!questionColumns.has(conciseText)) {
                    questionColumns.set(conciseText, {
                      field: conciseText,
                      headerName: conciseText,
                      width: 200,              
                      sortable: true,
                      filterable: true,
                      type: columnType, // Set the determined type
                    });
                  }
                  candidateData[conciseText] = question.answer_text;
                });
                
                return candidateData;
              });
              setStatusCounts(statusCounts);
              setColumns(initialColumns.concat(Array.from(questionColumns.values())));
              setRows(transformedData);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
              })
              .finally(() => {
                setIsLoading(false); // Stop loading
              });
        }
      };
    

      const funnelData2 = Object.entries(statusCounts)
      .filter(([status, _]) => showAll || keywords.some(keyword => status.includes(keyword))) // Apply filter based on showAll state
      .map(([status, count]) => [status, count])
      .sort((a, b) => b[1] - a[1]); // Sort the data by count in descending order


const handleToggleChange = (event) => {
    setShowAll(event.target.checked);
  };

  

      return (
        <div style={{ height: 600, width: '100%' }}>
          
      

         
      <div className="card">
        <div className="card-header">
          Candidate Screening Dashboard 
        </div>
        <div className="card-body">
        <div className="d-flex flex-row mb-3">
        <div className="p-2">Choose Job Posting from the dropdown to get started</div>
        <div className="p-2">  <select className='form-control'  value={selectedJobTitle} onChange={(e) => setSelectedJobTitle(e.target.value)}>
        <option value="">Choose Job Posting</option>
        {jobPostings.map(job => (
          <option key={job.id} value={job.title}>{job.title}</option>
        ))}
      </select></div>
        <div className="p-2"><button className='btn btn-primary' onClick={handleFetchCandidates} disabled={isDisabled}>{buttonLabel}</button></div>
        <div className="p-2"></div>
      </div>

      <div style={{ margin: '10px 0' }}>
      {Object.entries(statusCounts).map(([status, count]) => (
        <Chip  color="primary" variant="outlined"
          key={status}
          label={`${status}: ${count}`}
          style={{ marginRight: '5px', marginBottom: '5px' }}
        />
      ))}


    </div>
        </div>
      </div>

      <div class="col-6">
      <FormControlLabel
        control={<Switch checked={showAll} onChange={handleToggleChange} />}
        label="Show All Statuses"
      />
        <FunnelChart data={funnelData2}  />
        
        </div>      
          <DataGridPro 
            rows={rows}
            columns={columns}        
            pageSizeOptions={[100, 200, 300]}    
            onRowClick={handleRowClick}           
            checkboxSelection={false}            
            slots={{ toolbar: GridToolbar }} // Enable toolbar for column visibility control
            filterMode="client" // Use 'client' if you want to filter on the client-side
            pagination
            loading={isLoading}
            initialState={{sorting: {
              sortModel: [{ field: 'id', sort: 'desc' }],
            }, pinnedColumns: { left: ['user_current_status'], right: ['comments'] } }}
            sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#edeff6',  // Example color - blue
                  color: '#000000',  // Adjust text color if needed
                  // Add other styles as needed
                },
              }}
          />
         
        </div>
      );






}

export default CandidateFilter