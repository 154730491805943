import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';

const JobStatusGraph = () => {
    const [data, setData] = useState([]);
    const userToken = localStorage.getItem('authKey');
    const apiurl = process.env.REACT_APP_API_URL;
    const userType = localStorage.getItem('usertype'); // Fetch user type (admin or hrmanager)
    const userCategory = localStorage.getItem('usercategory'); // Fetch user category (admin or hrmanager)


    const fetchData = async () => {
        try {
            let endpoint = '/api/candidates/avgdaysperstatus';
            if (userCategory === 'hrmanager') {
                endpoint = '/api/candidates/avgdaysperstatus-by-hr-manager';
            }

            const response = await axios.get(apiurl + endpoint, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authKey')}`,
                    'Content-Type': 'application/json'
                }
            });

            const filteredData = response.data.filter(item => {
                // Filter out job titles with blank average days
                return Object.values(item.average_days).some(value => value !== null && value !== undefined && value !== 0);
            });

            setData(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [apiurl, userCategory]);

    useEffect(() => {
        // Check if data is available before accessing it
        if (data.length === 0) return;

        const transitions = data.reduce((acc, curr) => {
            Object.keys(curr.average_days).forEach(transition => {
                if (!acc.includes(transition)) acc.push(transition);
            });
            return acc;
        }, []);

        // Generate Highcharts configuration using job status transitions

        const categories = data.map(item => item.job_posting);
        const filteredTransitions = transitions.filter(transition => {
            // Check if any non-zero or non-null values exist for the transition
            return data.some(item => {
                const value = item.average_days[transition];
                return value !== undefined && value !== null && value !== 0;
            });
        });
        const seriesData = filteredTransitions.map(transition => {
            const fromStatus = transition.split("->")[0];
            const name = fromStatus.replace(/[()']/g, '').replace(',', ' → '); // Replace parentheses, single quotes, and comma with arrow
            return {
                name: name,
                data: data.map(item => {
                    const value = item.average_days[transition];
                    // Exclude if the value is 0
                    return (value !== undefined) ? value : null;
                }).map(value => (value !== null && value !== 0) ? value : null) // Remove null and zero values
            };
        }); // Reverse the order of the series

        // Generate legend items based on filtered transitions
        const legendItems = filteredTransitions.map(transition => {
            const fromStatus = transition.split("->")[0];
            return fromStatus.replace(/[()']/g, '').replace(',', ' → '); // Replace parentheses, single quotes, and comma with arrow
        });

        // Render Highcharts chart
        if (document.getElementById('container')) {
            Highcharts.chart('container', {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: 'Stage-to-Stage Transition Duration Analysis'
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: 'Job Title'
                    }
                },
                yAxis: {
                    title: {
                        text: 'Average Days'
                    }
                },
                legend: {
                    enabled: false, // Show legend
                    reversed: true, // Reverse legend order
                    labels: {
                        items: legendItems // Set legend items
                    }
                },
                plotOptions: {
                    series: {
                        stacking: 'normal', // Set stacking to 'normal'
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                tooltip: {
                    formatter: function () {
                        return 'The average duration for the transition from<br/><b> ' + this.series.name + '</b>' +
                            ' is approx.<b>' + this.y + ' days<b/>';
                    }
                },
                series: seriesData
            });
        }

    }, [data]);

    return <div id="container" style={{ height: '500px' }} />;
};

export default JobStatusGraph;
