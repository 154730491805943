import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Title from './Title';

const apiurl = process.env.REACT_APP_API_URL;

export default function Deposits() {
  const [recordCount, setRecordCount] = useState(0);

  useEffect(() => {
    axios.get(apiurl + '/api/candidates-count/', {
      headers: {
        Authorization: `Token ${localStorage.getItem('authKey')}`,
      }
    })
    .then(response => {
      console.log("Response:", response.data); // Log the response data
      // Determine where the count data is located and access it
      const count = response.data.total_candidates; // Example: if count is in response.data.total_candidates
      console.log("Count:", count); // Log the count
      setRecordCount(count);
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
  }, []);
  

  return (
    <React.Fragment>
      <Title>Candidates Applied</Title>
      <Typography component="p" variant="h4">
        {recordCount}
      </Typography>
    </React.Fragment>
  );
}