import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';


const userToken = localStorage.getItem('authKey');
const apiurl = process.env.REACT_APP_API_URL;

const JobStatusGraph = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiurl+'/api/candidate/status-change');
            setData(response.data);
            console.log(response.data); // Log the data to check its format
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data when component mounts

        const interval = setInterval(fetchData, 60000); // Poll data every minute
        return () => clearInterval(interval); // Clean up interval on component unmount
    }, []);

    useEffect(() => {
        // Check if data is available before accessing it
        if (data.length === 0) return;

        // Extract unique job status transitions
        const transitions = data.reduce((acc, curr) => {
            Object.keys(curr.average_days).forEach(transition => {
                if (!acc.includes(transition)) acc.push(transition);
            });
            return acc;
        }, []);

        // Generate Highcharts configuration using job status transitions
        const categories = data.map(item => item.job_posting);

        const seriesData = transitions.map(transition => {
            const name = transition.replace(/[()']/g, '').replace(',', ' → '); // Replace parentheses, single quotes, and comma with arrow
            return {
                name: name,
                data: data.map(item => item.average_days[transition] || 0)
            };
        }).reverse(); // Reverse the order of the series

        // Render Highcharts chart
        if (document.getElementById('container')) {
            Highcharts.chart('container', {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: 'Average Duration in Job Status Transitions'
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: 'Job Title'
                    }
                },
                yAxis: {
                    title: {
                        text: 'Average Days'
                    }
                },
                legend: {
                    reversed: true
                },
                plotOptions: {
                    series: {
                        stacking: 'normal', // Set stacking to 'normal'
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.series.name + '</b><br/>' +
                            'Average Days: ' + this.y + ' days';
                    }
                },
                series: seriesData
            });
        }
    }, [data]);

    return <div id="container" style={{ height: '400px' }} />;
};

export default JobStatusGraph;