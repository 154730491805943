import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import MD5 from 'crypto-js/md5';
import Box from '@mui/material/Box';
import CandidateHistoryTimeline from './CandidateTimeline';
import CandidateSkillSpiderChart from './CandidateSkillSpiderChart'


const OnboardCandidatesCount = () => {
  const [selectedCandidate, setSelectedCandidate] = useState({ id: null, name: "",job:"",notes:"" });

  const [selectedCandidateId, setSelectedCandidateId] = useState(null);

  const [candidates, setCandidates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchOnboardCandidates = async () => {
      try {
        const response = await fetch(apiurl + '/api/candidates/joined/', {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authKey')}`,
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        setCandidates(data); // Update state with candidate data
      } catch (error) {
        console.error('Failed to fetch onboard candidates:', error);
      }
    };

    fetchOnboardCandidates();
  }, [apiurl]);

  // Generate Gravatar URL
  const gravatarUrl = (email) => {
    const hash = MD5(email.trim().toLowerCase()).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=40`; // s=40 sets the size of the avatar
  };

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',    
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
    maxHeight: '90%',
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>{candidates.length}</h2>
      <AvatarGroup  max={3} style={{ justifyContent: 'center' }} onClick={() => setOpenModal(true)}>
        {candidates.map((candidate, index) => (
          <Tooltip key={index} title={capitalizeWords(candidate.name)} placement="top">


          <Avatar             
            key={index} 
            alt={candidate.name} 
            src={gravatarUrl(candidate.email)}             
          />
          </Tooltip>
        ))}
      </AvatarGroup>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
 <Box sx={style}>

<div className='row'>
<div className='col-4'>

  <table className='table table-striped'  style={{fontSize:'13px'}}>
            <thead>
              <tr>
                <th>Candidates </th> 
                <th>Notes</th>               
              </tr>
            </thead>
            <tbody>
  {candidates.map((candidate, index) => (
 <tr key={index} onClick={() => setSelectedCandidate({ id: candidate.id, name: candidate.name,job:candidate.job_posting_title,notes:candidate.comments })} style={{ cursor: 'pointer', backgroundColor: selectedCandidate.id === candidate.id ? '#000000' : '#cccccc' }}>
 <td>{capitalizeWords(candidate.name)}</td>
 <td>{candidate.comments}</td>
</tr>
  ))}
</tbody>
          </table></div>
          <div className='col-8' style={{ minHeight:'500px',maxHeight: '700px', overflow: 'scroll' }}>
  {selectedCandidate.id ? (
    <>
      <p><span style={{fontSize:'20px',fontWeight:'bold'}}>{capitalizeWords(selectedCandidate.name)}  </span>   <br/><span style={{fontSize:'12px'}}> Role  {selectedCandidate.job}</span></p> 
   
      <hr/>
      <CandidateHistoryTimeline candidateId={selectedCandidate.id} />
      <CandidateSkillSpiderChart candidateId={selectedCandidate.id} />
    </>
  ) : (
    <p>Select a candidate to view their history.</p>
  )}
</div>

</div> 
        </Box>
      </Modal>
    </div>   
  );
};

export default OnboardCandidatesCount;
