import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DynamicBoxes.css'; // Create and import a CSS file for styling

const DynamicBoxes = ({ updatedUserId }) => {
  const [data, setData] = useState(null);
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiurl}/api/status-dropoffs/${updatedUserId}/`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [updatedUserId, apiurl]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const { total_counts, dropoff_counts } = data;

  return (
    <div className="dynamic-boxes-container">
      {Object.entries(total_counts).map(([status, totalCount]) => {
        const dropoffCount = dropoff_counts[status] || 0;
        const percentage = ((dropoffCount / totalCount) * 100).toFixed(2);
        return (
          <div key={status} className="dynamic-box">
            <div className="box-content">
              <div className="box-body">
                <div className="count"><span style={{ color: 'green' }}>{totalCount}</span></div>
                <div className="box-header">{status}</div>
                <div className="dropoff-count">{dropoffCount}</div>
                <div className="percentage">{percentage}%</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DynamicBoxes;
