import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Button, Typography } from '@mui/material';

const JobStatusList = () => {
    const [jobStatuses, setJobStatuses] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [newStatus, setNewStatus] = useState(null);

    const apiurl = process.env.REACT_APP_API_URL;


    useEffect(() => {
        fetchJobStatuses();
    }, []);

    const fetchJobStatuses = () => {
        axios.get(apiurl+'/api/job-status/')
            .then(response => {
                setJobStatuses(response.data);
            })
            .catch(error => {
                console.error('Error fetching job statuses:', error);
            });
    };

    const handleStatusChange = (id) => {
        axios.patch(apiurl+`/api/job-status/${id}/`, { status: newStatus })
            .then(response => {
                console.log('Job status updated successfully:', response.data);
                const updatedStatuses = jobStatuses.map(status => {
                    if (status.id === id) {
                        return { ...status, status: newStatus };
                    }
                    return status;
                });
                setJobStatuses(updatedStatuses);
                setEditingId(null);
            })
            .catch(error => {
                console.error('Error updating job status:', error);
            });
    };
    
    const handleDoubleClick = (id) => {
        setEditingId(id);
    };

    const handleSubmit = (id) => {
        if (window.confirm("Are you sure you want to submit?")) {
            handleStatusChange(id);
        }
    };

    // Function to format date in a user-friendly way
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h4" gutterBottom>Job Statuses</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Job Title</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Required Candidates</TableCell>
                            <TableCell>Created Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobStatuses.map(jobStatus => (
                            <TableRow key={jobStatus.id}>
                                <TableCell>{jobStatus.title}</TableCell>
                                <TableCell 
                                    onDoubleClick={() => handleDoubleClick(jobStatus.id)}
                                    style={{ cursor: 'pointer' }} // Add cursor pointer
                                    className="status-cell" // Add class for styling
                                >
                                    {editingId === jobStatus.id ? (
                                        <div>
                                            <Select value={newStatus} onChange={(e) => setNewStatus(parseInt(e.target.value))}>
                                                <MenuItem value={1}>Active</MenuItem>
                                                <MenuItem value={0}>Inactive</MenuItem>
                                            </Select>
                                            <Button onClick={() => handleSubmit(jobStatus.id)}>Submit</Button>
                                        </div>
                                    ) : (
                                        jobStatus.status === 1 ? 'Active' : 'Inactive'
                                    )}
                                </TableCell>
                                <TableCell>{jobStatus.required_candidates}</TableCell>
                                <TableCell>{formatDate(jobStatus.created_date)}</TableCell> {/* Format date */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default JobStatusList;
