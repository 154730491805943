import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Rating from '@mui/material/Rating';

const AverageScore = ({ candidateId }) => {
    const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;
  const [averageScore, setAverageScore] = useState(null);

  useEffect(() => {
    const fetchAverageScore = async () => {
      try {
        const response = await fetch(apiurl+`/api/average-score-per-candidate/${candidateId}/`);
        if (!response.ok) {
          throw new Error('Failed to fetch average score');
        }
        const data = await response.json();
        setAverageScore(data.average_score);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAverageScore();
  }, [candidateId]);

  // Calculate rating out of 5
  const rating = averageScore !== null ? (averageScore / 10) * 5 : null;

  return (
    <div>
     
      {averageScore !== null ? (
        <div>
          <p style={{ fontWeight: 'bold', fontSize: '30px'}}> {averageScore.toFixed(2)}/10</p>
          <Rating name="average-score" value={rating} precision={0.1} readOnly />
        </div>
      ) : (
        <p>No Score Yet</p>
      )}
    </div>
  );
};

export default AverageScore;
