import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import  { useState, useEffect } from 'react';
import axios from 'axios';

function preventDefault(event) {
  event.preventDefault();
}

const apiurl = process.env.REACT_APP_API_URL;
const proddomain = process.env.REACT_APP_PROD_URL;

export default function RecordCountToday() {

    const [todayCount, setTodayCount] = useState(0);

    useEffect(() => {
      axios.get(apiurl + '/api/candidates-count/', {
        headers: {
          Authorization: `Token ${localStorage.getItem('authKey')}`,
        },
      })
      .then(response => {
        const today = new Date().toISOString().split('T')[0]; // Gets today's date in YYYY-MM-DD format
        const count = response.data.candidates_applied_today; // Assuming the response contains a property 'candidates_applied_today' with today's count
        setTodayCount(count);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
    }, []);

  return (
    <React.Fragment>
      <Title>Registered Today</Title>
      <Typography component="p" variant="h4">
        {todayCount}
      </Typography>
    </React.Fragment>
  );
}
