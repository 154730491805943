import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CandidateCompanySkillFilter = ({ onApplyFilter, onClose }) => { // Added onClose prop
  const [companies, setCompanies] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [operator, setOperator] = useState('OR');
  const [candidates, setCandidates] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(apiurl + '/api/get/')
      .then(response => response.json())
      .then(data => setCompanies(data));

    fetch(apiurl + '/api/skills/')
      .then(response => response.json())
      .then(data => setSkills(data));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const filteredCompanies = selectedCompanies.filter(company => company !== null);

    const requestBody = {
      company_names: filteredCompanies,
      skill_names: selectedSkills,
      operator: operator,
    };

    fetch(apiurl + '/api/filter-candidates/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch candidates');
        }
        return response.json();
      })
      .then(data => {
        setCandidates(data);
        setFilteredCandidates(data);
        onApplyFilter(data.map(candidate => candidate.candidate_id));
      })
      .catch(error => {
        console.error('Error fetching candidates:', error);
        setCandidates([]);
        setFilteredCandidates([]);
      });
  };

  const handleClearSelection = () => {
    setSelectedCompanies([]);
    setSelectedSkills([]);
    setFilteredCandidates([]);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', padding: 3, position: 'relative' }}>
      <IconButton
        sx={{ position: 'absolute', top: 8, right: 8 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4" gutterBottom>Filter Candidates</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="company-label">Select Companies</InputLabel>
          <Select
            labelId="company-label"
            multiple
            value={selectedCompanies}
            onChange={(e) => setSelectedCompanies(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {companies.map((company, index) => (
              <MenuItem key={index} value={company.company}>
                {company.company}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="skill-label">Select Skills</InputLabel>
          <Select
            labelId="skill-label"
            multiple
            value={selectedSkills}
            onChange={(e) => setSelectedSkills(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {skills.map((skill, index) => (
              <MenuItem key={index} value={skill.name}>
                {skill.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="operator-label">Operator</InputLabel>
          <Select
            labelId="operator-label"
            value={operator}
            onChange={(e) => setOperator(e.target.value)}
          >
            <MenuItem value="OR">OR</MenuItem>
            <MenuItem value="AND">AND</MenuItem>
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">Filter Candidates</Button>
        <Button variant="contained" onClick={handleClearSelection} sx={{ ml: 2 }}>Clear Selection</Button>
      </form>
      {filteredCandidates.length === 0 && (
        <Box mt={4}>
          <Typography variant="body1" color="error">No candidates found with the provided filters. Please try different criteria.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CandidateCompanySkillFilter;
