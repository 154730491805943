import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SkillRating from './CandidateNewSkillRating'; // Import the SkillRating component

const CandidateQuestion = () => {
  const { candidateId } = useParams();
  const [questions, setQuestions] = useState([]);
  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCandidateQuestions = async () => {
      try {
        const response = await fetch(apiurl + `/api/candidate/getqa/${candidateId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch candidate questions');
        }
        const data = await response.json();
        setQuestions(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCandidateQuestions();
  }, [candidateId]);

  const convertToStars = (answerText) => {
    const rating = parseFloat(answerText);
    return rating / 10; // Converting to a scale out of 5
  };

  const removeStopwords = (text) => {
    if (!text) return ''; // Return an empty string if text is null or undefined

    const stopwords = ['are', 'is', 'the', 'your', 'would', 'rate', 'how', 'you', 
    'skills', 'with', 'whats', 'on', 'scale', 'of', 'a', 'work', 'as', 'experience', 
    "what's",'long','have','worked','solely','what','in','writing','complex','many',
    'years','spent','working','various','approaches','building','using','implementing',
    'tools','like','do','and','maintaining','any','following','technologies','technologies:','these','to','proficiency'];
    const words = text.split(' ');

    const filteredWords = words.filter(word => !stopwords.includes(word.toLowerCase()) && !/\d/.test(word));
    const capitalizedString = filteredWords.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedString.join(' ');
  };

  // Check if the questions array is empty and display a message if true
  if (questions.length === 0) {
    return (
      <div style={{ paddingTop: '30px', textAlign: 'center' }}>
        <h4>Skills & Expertise</h4>
    <hr/>
        No Skills to Show.
      </div>
    );
  }

  return (
    <div className='row' style={{ paddingTop: '30px' }}>
      {questions.some(question => question.question.unit.toUpperCase() === 'STARS') && (
        <div style={{ marginBottom: '20px' }}><h4>Skills & Expertise</h4>
        <hr/>
          {questions.map((question, index) => (
            question.question.unit.toUpperCase() === 'STARS' && question.answer_text !== 0 && (
              <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                <p style={{ marginRight: '10px', marginBottom: '0', width: '150px' }}>{removeStopwords(question.question.question_text)}</p>
                <SkillRating skill={question.question_text} level={convertToStars(question.answer_text)} style={{ marginLeft: '-10px' }} />
              </div>
            )
          ))}
        </div>
      )}

      {questions.some(question => question.question.unit.toUpperCase() === 'YEARS') && (
        <div style={{ marginBottom: '20px' }}>
          <h4>Years of Experience</h4>
          <hr/>
          {questions.map((question, index) => (
            question.question.unit.toUpperCase() === 'YEARS' && (
              <p key={index}>{removeStopwords(question.question.question_text)}: {question.answer_text} Years</p>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default CandidateQuestion;
