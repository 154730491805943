import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';

// Initialize Highcharts modules
highchartsMore(Highcharts);
solidGauge(Highcharts);


const userToken = localStorage.getItem('authKey');
const apiurl = process.env.REACT_APP_API_URL;
const userType = localStorage.getItem('usertype'); // Fetch user type (admin or hrmanager)
const userCategory = localStorage.getItem('usercategory'); // Fetch user category (admin or hrmanager)


const AverageOnboardDaysGauge = () => {
  const [averageDays, setAverageDays] = useState(0);

  useEffect(() => {
    const fetchAverageOnboardDays = async () => {
        try {
            let endpoint = '/api/average-onboard-days/';
            if (userCategory === 'hrmanager') {
                endpoint = '/api/average-onboard-days-by-hr-manager/';
            }
            const response = await fetch(apiurl + endpoint, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authKey')}`,
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            setAverageDays(data.average_days_to_onboard);
        } catch (error) {
            console.error('Fetching average onboard days failed:', error);
        }
    };

    fetchAverageOnboardDays();
}, [apiurl, userCategory]);

  const chartOptions = {
    chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%'
    },

    title: {
        text: 'Avg. Time to Hire'
    },
	credits: {
     enabled: false
},
    pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
    },

    // the value axis
    yAxis: {
        min: 1,
        max: 60,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
            distance: 20,
            style: {
                fontSize: '12px'
            }
        },
        lineWidth: 0,
        plotBands: [{
            from: 0,
            to: 20,
            color: '#55BF3B', // green
            thickness: 20
        }, {
            from: 20,
            to: 45,
            color: '#DDDF0D', // yellow
            thickness: 20
        }, {
            from: 45,
            to: 60,
            color: '#DF5353', // red
            thickness: 20
        }]
    },

    series: [{
        name: '',
        data: [averageDays],
        tooltip: {
            valueSuffix: ' Days to Hire'
        },
        dataLabels: {
            format: '{y} Days',
            borderWidth: 0,
            color: (
                Highcharts.defaultOptions.title &&
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
            ) || '#333333',
            style: {
                fontSize: '14px'
            }
        },
        dial: {
            radius: '80%',
            backgroundColor: 'gray',
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%'
        },
        pivot: {
            backgroundColor: 'gray',
            radius: 6
        }

    }]
  };

  return (
    <>
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
   
    </>
  );
};

export default AverageOnboardDaysGauge;