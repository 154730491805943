import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios'; // Import Axios for making HTTP requests

const apiurl = process.env.REACT_APP_API_URL;
const userToken = localStorage.getItem('authKey');
const GraphComponent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiurl + '/api/applied-on-dates/'); // Adjust the URL as per your Django server setup
        setData(response.data.applied_on_dates);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formattedData = data; // No need to process the data, it's already in the desired format

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis label={{ value: 'Total Users', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} /> {/* Use "count" as dataKey */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GraphComponent;
