import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Button, Typography } from '@mui/material';

const HRManagerAccessList = () => {
    const [hrManagerAccessList, setHRManagerAccessList] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [newStatus, setNewStatus] = useState(null);

    const apiurl = process.env.REACT_APP_API_URL;


    useEffect(() => {
        fetchHRManagerAccessList();
    }, []);

    const fetchHRManagerAccessList = () => {
        axios.get(apiurl+'/api/hr-manager-access/')
            .then(response => {
                setHRManagerAccessList(response.data);
            })
            .catch(error => {
                console.error('Error fetching HR manager access list:', error);
            });
    };

    const handleStatusChange = (id) => {
        axios.patch(apiurl+`/api/hr-manager-access/${id}/`, { status: newStatus })
            .then(response => {
                console.log('HR manager access updated successfully:', response.data);
                const updatedAccessList = hrManagerAccessList.map(access => {
                    if (access.id === id) {
                        return { ...access, status: newStatus };
                    }
                    return access;
                });
                setHRManagerAccessList(updatedAccessList);
                setEditingId(null);
            })
            .catch(error => {
                console.error('Error updating HR manager access:', error);
            });
    };
    
    const handleDoubleClick = (id) => {
        setEditingId(id);
    };

    const handleSubmit = (id) => {
        if (window.confirm("Are you sure you want to submit?")) {
            handleStatusChange(id);
        }
    };

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h4" gutterBottom>Job Listing Access List</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>HR Manager Name</TableCell>
                            <TableCell>Job Title</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hrManagerAccessList.map(access => (
                            <TableRow key={access.id}>
                                <TableCell>{access.hr_manager_name}</TableCell>
                                <TableCell>{access.job_title}</TableCell>
                                <TableCell 
                                    onDoubleClick={() => handleDoubleClick(access.id)}
                                    style={{ cursor: 'pointer' }} // Add cursor pointer
                                    className="status-cell" // Add class for styling
                                >
                                    {editingId === access.id ? (
                                        <div>
                                            <Select value={newStatus} onChange={(e) => setNewStatus(parseInt(e.target.value))}>
                                                <MenuItem value={1}>Active</MenuItem>
                                                <MenuItem value={0}>Inactive</MenuItem>
                                            </Select>
                                            <Button onClick={() => handleSubmit(access.id)}>Submit</Button>
                                        </div>
                                    ) : (
                                        access.status === 1 ? 'Active' : 'Inactive'
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default HRManagerAccessList;
