import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, CircularProgress, Alert, List, ListItem, ListItemText, Paper, Container, Button } from '@mui/material';
import DOMPurify from 'dompurify';



const apiurl = process.env.REACT_APP_API_URL;

const CandidateAnswers = ({ candidateId, candidateTestId, open, onClose }) => {
    const [answers, setAnswers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (open) {
            const fetchAnswers = async () => {
                try {
                    let url = `${apiurl}/candidate-answers/`;
                    if (candidateId) {
                        url += `?candidate_id=${candidateId}`;
                    } else {
                        // Assume candidate_test_id is present in your state or props
                        url += `?candidate_test_id=${candidateTestId}`;
                    }
                    const response = await axios.get(url);
                    setAnswers(response.data);
                    setLoading(false);
                } catch (err) {
                    setError(err);
                    setLoading(false);
                }
            };
            fetchAnswers();
        }
    }, [candidateId, candidateTestId, open]);
    

    if (error) return <Alert severity="error">Error fetching data: {error.message}</Alert>;

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxHeight: '90%', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, overflowY: 'auto' }}>
            <Button style={{float:'right'}} variant="contained" color="primary" onClick={onClose}>Close</Button>

                <Container>
                    <h5>
                        Candidate Answers
                    </h5>

                    <Paper>
                    
                        <List>
                            {answers.map((answer) => (
                                <ListItem key={answer.id} divider>
                                    <ListItemText
                                        primary={
                                            <p style={{ fontSize: 13 }}
                                        >
                                                <span style={{ fontWeight:'bold' }}>Question:</span> 
                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer.question_text) }} />
                                            </p>
                                        }
                                        secondary={
                                            <div>
                                                <p style={{ fontSize: 13 }}>
                                                    {answer.all_choices.map((choice) => (
                                                        <span key={choice.id}>
                                                            {choice.text}
                                                            {choice.is_correct ? <span style={{ fontWeight:'bold', color: 'green' }}> (Correct Answer)</span> : ''}
                                                            {choice.text === answer.choice_text ? ' (Chosen)' : ''}
                                                           
                                                            <br />


                                                        </span>
                                                    ))}
                                                </p>
                                            </div>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Container>
            </Box>
        </Modal>
    );
};

export default CandidateAnswers;
