import React, { useState, useEffect } from 'react';
import MD5 from 'crypto-js/md5';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import CandidateOverallScore from './CandidateOverallScore';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const OnboardCandidatesCount = ({ jobPostingTitle, status, type }) => {
  
  
  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;
  const prod_url = process.env.FRONTEND_URL;

  const userType = localStorage.getItem('usertype'); // Fetch user type (admin or hrmanager)
  const userCategory = localStorage.getItem('usercategory'); // Fetch user category (admin or hrmanager)
  const handleRowClick = (params) => {
    const url = window.location.origin + `/admin/dash/candidateProfile/${params.id}`;
    window.open(url, '_blank');
  };

  const capitalizeWords = (str) => {
    if (str && typeof str === 'string') {
      return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    return '';
  };
  

  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    const fetchCandidateHistory = async () => {
        try {
            let endpoint = '/api/candidate-history/status';
            if (userCategory === 'hrmanager') {
                endpoint = '/api/candidate-history/status-by-hr-manager';
            }

            const url = `${apiurl}${endpoint}?job_posting_title=${jobPostingTitle}&status=${status}`;
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${userToken}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('print', data);
            // Map the data to match the fields used in the columns and use candidate's id as id
            const mappedData = data.map(candidate => ({
                id: candidate.candidate, // Use candidate's id as the id
                email: candidate.candidate_email,
                name: capitalizeWords(candidate.candidate_name),
                job_posting_title: candidate.job_posting_title,
                user_current_status: candidate.user_current_status,
                score: candidate.candidate_id,
            }));
            setCandidates(mappedData);
        } catch (error) {
            console.error('Failed to fetch candidate history:', error);
        }
    };

    const fetchCandidateHistory2 = async () => {
      debugger;
      try {
        let endpoint = '/api/candidate-history/status';
        if (userCategory === 'hrmanager') {
            endpoint = '/api/candidate-history/status-by-hr-manager';
        }

        const url = `${apiurl}${endpoint}?job_posting_title=${jobPostingTitle}&status=${status}`;
        const response = await fetch(url, {
            headers: {
                'Authorization': `Token ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
      }
        const data = await response.json();
        console.log('print',data);
        // Map the data to match the fields used in the columns and use candidate's id as id
        const mappedData = data.map(candidate => ({
          id: candidate.candidate, // Use candidate's id as the id
          email: candidate.candidate_email,
          name: capitalizeWords(candidate.candidate_name),
          job_posting_title: candidate.job_posting_title,
          user_current_status: candidate.user_current_status,
          score: candidate.candidate_id,
        }));
        setCandidates(mappedData);
      } catch (error) {
        console.error('Failed to fetch candidate history:', error);
      }
    };
    
  
    const fetchOnboardCandidates = async () => {
      try {
        let endpoint = '/api/candidates/getbystatusandrole';
        if (userCategory === 'hrmanager') {
            endpoint = '/api/candidate/getbystatusandrole-by-hr-manager';
        }

        const url = `${apiurl}${endpoint}?job_posting_title=${jobPostingTitle}&status=${status}`;
        const response = await fetch(url, {
            headers: {
                'Authorization': `Token ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        setCandidates(data);
      } catch (error) {
        console.error('Failed to fetch onboard candidates:', error);
      }
    };

    const fetchOnboardStatusCandidates = async () => {
      try {
        let endpoint = '/api/candidates/getbystatusandrole';
        if (userCategory === 'hrmanager') {
            endpoint = '/api/candidate/getbystatusandrole-by-hr-manager';
        }

        const url = `${apiurl}${endpoint}?job_posting_title=${jobPostingTitle}&status=${status}`;
        const response = await fetch(url, {
            headers: {
                'Authorization': `Token ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        setCandidates(data);
      } catch (error) {
        console.error('Failed to fetch onboard candidates:', error);
      }
    };

    

    const fetchOnboardJobtitleCandidates = async () => {
      try {
        let endpoint = '/api/candidates/getbystatusandrole';
        if (userCategory === 'hrmanager') {
            endpoint = '/api/candidate/getbystatusandrole-by-hr-manager';
        }

        const url = `${apiurl}${endpoint}?job_posting_title=${jobPostingTitle}&status=${status}`;
        const response = await fetch(url, {
            headers: {
                'Authorization': `Token ${userToken}`,
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        setCandidates(data);
      } catch (error) {
        console.error('Failed to fetch onboard candidates:', error);
      }
    };

    if (jobPostingTitle && status && type) {
      fetchCandidateHistory();
      debugger;
      console.log("condtion1:",jobPostingTitle,status,type);
    }
      else if (status && type){
        fetchCandidateHistory2();
    } else if (jobPostingTitle && status) {
      fetchOnboardCandidates();
      console.log("condtion2:",jobPostingTitle,status,type);
    } else if (status) {
      fetchOnboardStatusCandidates();
      console.log("condtion3:",jobPostingTitle,status,type);
    } else if (jobPostingTitle) {
      fetchOnboardJobtitleCandidates();
      console.log("condtion4:",jobPostingTitle,status,type);
    }

  },[apiurl, jobPostingTitle, status, userCategory, userToken]);

  const gravatarUrl = (email) => {
  if (email && typeof email === 'string') {
    const hash = MD5(email.trim().toLowerCase()).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=100`;
  }
  // Return a default URL if email is not defined or not a string
  return 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=identicon&s=100';
};


  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'avatar',
      headerName: 'Profile',
      renderCell: (params) => (
        <img src={gravatarUrl(params.row.email)} alt="" style={{ width: 60, height: 60, borderRadius: '50%' }} />
      ),
      width: 100,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      renderCell: (params) => (
        <Typography>{params.value}</Typography>
      ),
    },
    {
      field: 'job_posting_title',
      headerName: 'Job Title',
      width: 200,
    },
    {
      field: 'user_current_status',
      headerName: 'Status',
      width: 200,
    },
    {
      field: 'score',
      headerName: 'Score',
      renderCell: (params) => <CandidateOverallScore candidateId={params.value} />,
      width: 150,
      sortable: false,
    },
  ];

  const uniqueIds = new Set(); // Create a Set to store unique IDs

const rows = candidates.reduce((acc, candidate) => {
  // Check if the ID is already in the Set
  if (!uniqueIds.has(candidate.id)) {
    // If not, add it to the Set and push it to the rows array
    uniqueIds.add(candidate.id);
    acc.push({
      id: candidate.id,
      email: candidate.email,
      name: capitalizeWords(candidate.name),
      job_posting_title: candidate.job_posting_title,
      user_current_status: candidate.user_current_status,
      score: candidate.id, // Assuming score is determined by the candidate's ID for demonstration
    });
  }
  return acc;
}, []);


  return (
    <Box sx={{ height: 800, width: '100%' }}>
      Showing {jobPostingTitle} with {status} status
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        autoHeight
        disableSelectionOnClick
        rowHeight={100}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};

export default OnboardCandidatesCount;