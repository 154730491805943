import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import MD5 from 'crypto-js/md5';
import Box from '@mui/material/Box';
import CandidateHistoryTimeline from './CandidateTimeline';
import CandidateSkillSpiderChart from './CandidateSkillSpiderChart'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import CandidateProfileListingDatagrid from './CandidateProfileListingDatagrid';
const OnboardCandidatesCount = () => {
  const [selectedCandidate, setSelectedCandidate] = useState({ id: null, name: "", job: "", notes: "" });
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const userToken = localStorage.getItem('authKey');
  const apiurl = process.env.REACT_APP_API_URL;
  const userType = localStorage.getItem('usertype'); // Fetch user type (admin or hrmanager)
  const userCategory = localStorage.getItem('usercategory'); // Fetch user category (admin or hrmanager)

  useEffect(() => {
    const fetchOnboardCandidates = async () => {
      try {
        let endpoint = '/api/candidates/onboard/'; // Default API endpoint for admin
        if (userCategory === 'hrmanager') {
          endpoint = `/api/onboard-candidates-by-hr-manager/`; // Use HR manager's endpoint if user is HR manager
        }
  
        const response = await fetch(apiurl + endpoint, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('authKey')}`,
            'Content-Type': 'application/json'
          },
        });
  
        const data = await response.json();
        console.log('Fetched candidates:', data); // Add this line to inspect the fetched data
  
        // Update state with candidate data
        setCandidates(data);
      } catch (error) {
        console.error('Failed to fetch onboard candidates:', error);
      }
    };
  
    fetchOnboardCandidates();
  }, [apiurl, userCategory]); // Include userCategory in the dependency array


  // Generate Gravatar URL
  const gravatarUrl = (email) => {
    const hash = MD5(email.trim().toLowerCase()).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=500`; // s=40 sets the size of the avatar
  };

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  maxHeight: '90%',
};


  return (
    <div style={{ textAlign: 'center' }}>
      <h2>{candidates.length}</h2>
      <AvatarGroup max={5} style={{ justifyContent: 'center' }} onClick={() => setOpenModal(true)}>
        {/* Add a conditional rendering to handle the case when candidates is not an array */}
        {Array.isArray(candidates) && candidates.map((candidate, index) => (
          <Tooltip key={index} title={capitalizeWords(candidate.name)} placement="top">
            <Avatar
              key={index}
              alt={candidate.name}
              src={gravatarUrl(candidate.email)}
            />
          </Tooltip>
        ))}
      </AvatarGroup>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='row'>
            <CandidateProfileListingDatagrid jobPostingTitle="" status="Onboard" />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default OnboardCandidatesCount;